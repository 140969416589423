<template >
  <div>
    <main>
    <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>Customer</h1>
						<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0">
								<!--<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>-->
								<li class="breadcrumb-item">
									<a href="/customer-list">Customer</a>
								</li>
								<li class="breadcrumb-item active text-gray" aria-current="page">Add Customer</li>
							</ol>
						</nav>

                    </div>



                    <div class="mb-3"></div>
					
				
					<div class="d-flex justify-content-center mb-4">

						
					
					<div class="col-sm-10 col-md-8 col-lg-6">
					<div class="card h-100 d-flex justify-content-center flex-row">
					<div class="card-body col-md-9 col-lg-8">
            <div class="row">
							<div class="wr col-sm-12 mb-4">
								<label>Phone :</label>
								<input type="tel" class="form-control" placeholder="">
							</div>

              <div class="wr col-sm-12 mb-4">
								<label>Email :</label>
								<input type="email" class="form-control" placeholder="">
							</div>

              <div class="wr col-sm-12 mb-4">
								<label>Name :</label>
								<input type="text" class="form-control" placeholder="">
							</div>

              <div class="wr col-sm-12 mb-4">
								<label>Address :</label>
								<textarea class="form-control" rows="2" name="jQueryDetail" required=""></textarea>
							</div>

							<!-- <div class="wr col-sm-12 mb-4">
								<label>Status :</label>
								<select class="form-control select2-single select2-hidden-accessible" data-width="100%">
									<option value="0" selected="">Active</option>
									<option value="1">Disable</option>
								</select>
							</div> -->
						</div>								
		
														
						<div class="d-flex justify-content-between mb-4">
							
								<a href="javascript:history.back();">
								<button type="button" class="btn btn-gray btn-sm mb-2 mr-1 min-w">
								
								Back</button></a>

                <button type="button" class="btn btn-primary btn-sm mb-2 ml-1 min-w">
								
								Save</button>
						</div>
					</div>
					</div>
					</div>
					</div>
					
					
					
					
					
                </div>
            </div>
        </div>
  </main>

  </div>
</template>
<script>

export default {
  name: 'Login',
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {

  },
  created () {
    
  },
  mounted () {
    

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
