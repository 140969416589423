<template >
  <div>
    <main>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>Privacy</h1>
						<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0">
								<!--<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>
								<li class="breadcrumb-item">
									<a href="#">Library</a>
								</li>-->
								<li class="breadcrumb-item active text-gray" aria-current="page">Approve</li>
							</ol>
						</nav>
                        <!--<div class="top-right-button-container">

                            <div class="btn-group">
                                <div class="btn btn-primary btn-lg pl-4 pr-0 check-button">
                                    <label class="custom-control custom-checkbox mb-0 d-inline-block">
                                        <input type="checkbox" class="custom-control-input" id="checkAll">
                                        <span class="custom-control-label">&nbsp;</span>
                                    </label>
                                </div>
                                <button type="button"
                                    class="btn btn-lg btn-primary dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                </div>
                            </div>
                        </div>-->
                    </div>

                    <!-- <div class="mb-2 d-flex justify-content-between">
						<div class="col-l">
							<a class="btn pt-0 pl-0 d-inline-block d-md-none" data-toggle="collapse" href="#displayOptions"
								role="button" aria-expanded="true" aria-controls="displayOptions">
								Display Options
								<i class="simple-icon-arrow-down align-middle"></i>
							</a>
							<div class="collapse d-md-block" id="displayOptions">
								<div class="d-flex align-items-end">

									
									
									<div class="input-daterange no-gutters d-flex flex-nowrap align-items-end mr-2 mb-1" id="datepicker-report"> 
										<div class="col mb-0">
											<label class="d-block">Start date :</label>
											<input type="text" class="form-control form-control-sm bg-white rounded-2" name="Select date"
														placeholder="Start" value="05/04/2020" />
										</div>
										<span class="form-group pl-2 pr-2 mb-0"><label>to</label></span>
										<div class="col mb-0">
											<label class="d-block">End date :</label>
											<input type="text" class="form-control form-control-sm bg-white rounded-2" name="Select date"
														placeholder="End" value="05/15/2020" />
										</div>
									</div>

	
									<div class="float-md-left mr-1 mb-1">
										
										<button class="btn btn-primary btn-xs text-white" type="button">
											Search
										</button>
										
									</div>

								</div>
							</div>
						</div>
					</div> -->

					<div class="srh-bar mb-4 d-flex justify-content-between flex-row flex-nowrap">
						<div class="col p-0 pl-1 pr-0">
							<a class="btn p-2 d-inline-block d-md-none" data-toggle="collapse" href="#searchOptions" role="button" aria-expanded="true" aria-controls="searchOptions">
								Display Options
								<i class="simple-icon-arrow-down align-middle"></i>
							</a>
							<div class="card collapse d-md-block" id="searchOptions">
								<div class="card-body p-3 d-flex flex-wrap justify-content-between h-100 align-items-end">
									<div class="d-flex flex-wrap w-100 mb-3">
									
										
										<div class="col-12 col-sm form-group mb-2">
											<label>Privacy Title</label>
											<input type="text" class="form-control" />

										</div>

										<div class="col-12 col-sm form-group mb-2">
											<label>Privacy Type</label>
											<select class="form-control select2-single" data-width="100%" data-placeholder="Select Consent Status" data-minimum-results-for-search="Infinity">
												<option>All Type</option>
												<option>Public</option>
												<option>Private</option>
											</select>

										</div>
										
										
									</div>

									<div class="d-flex flex-wrap w-100 align-items-end">

										<div class="col-12 col-sm form-group mb-2">
											<label>Period</label>
											<div class="row mb-0 align-items-center">
												<div class="col pr-2">
													<div class="input-group">
															<span class="input-group-text input-group-append input-group-addon border-right-0">
																<i class="simple-icon-calendar"></i>
															</span>
															<input type="text" class="input-sm form-control border-left-0" name="start" placeholder="Start date">
														</div>

												</div>
												<div class="font-weight-bold">To</div>
												<div class="col pl-2">
													<div class="input-group">
															<span class="input-group-text input-group-append input-group-addon border-right-0">
																<i class="simple-icon-calendar"></i>
															</span>
															<input type="text" class="input-sm form-control border-left-0" name="end" placeholder="End date">
														</div>
												</div>
											</div>
										</div>
										
										<div class="col-12 col-sm form-group mb-2">
											<label>Status</label>
											<select class="form-control select2-single" data-width="100%" data-placeholder="Select Consent Status" data-minimum-results-for-search="Infinity">
												<option>All Status</option>
												<option>Draft</option>
												<option>Active</option>
												<option>In-Active</option>
												<option>Expired</option>
											</select>

										</div>


										<div class="top-right-button-container text-nowrap col-12 col-sm-auto mb-2">

											<button class="btn btn-primary btn-md top-right-button rounded-05" type="button" id="btnSearch" style="min-width: 120px"> <!--<i class="icon-img"><img src="di/ic-search-wh.png" height="20"></i>--> Search</button>
										</div>
										
									</div>

								</div>
							</div>
						</div>

					</div>

                    <div class="separator mb-5"></div>

					
					<div class="card main-consent-setting">
					<div class="card-body">
						<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
							<!--<table id="tablelist" class="data-table data-table-feature">-->
							<!--<table id="tablelist" class="data-table data-table-standard">-->
                           <table id="tb_paging" class="responsive nowrap">
							
									<thead>
										<tr>
											<th>Request Date Time</th>
											<th>Type</th>
											<th>Title</th>
											<th>Consent Start Date</th>
											<th>Consent End Date</th>
											<th>Current Version</th>
											<th>New Version</th>
											<th class="text-center sort-none" width="20%">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>2020-09-01 10:08:09</td>
											<td>Private</td>
											<td>แจ้งเตือนขอเก็บข้อมูลทั่วไป	</td>
											<td>01-Jan-2020</td>
											<td>31-Dec-2020</td>
											<td>1.0.3</td>
											<td>1.0.4</td>
											<td class="text-center">
												<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#comparePop" class="btn btn-primary mr-2 btn-sm">Compare</a>
												<a href="/consent-detail" class="btn btn-primary mr-2 btn-sm">Approve</a>
												<a href="#" data-toggle="modal" data-backdrop="static" data-target="#rejectPopUp" class="btn btn-danger btn-sm">Reject</a>
											</td>
										</tr>
									</tbody>
								</table>
								
		

						</div>
						
					</div>
					</div>
                </div>
            </div>
        </div>


		<!-- Edit modal -->
    <div class="modal fade show" id="rejectPopUp">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Reject Reson</h5>
                  
                </div>
                <form>
                <div class="modal-body">
                    
                      <div class="form-group cv-select-cr">
                        <label>Reason :</label>
                        <textarea class="form-control" rows="10" name="jQueryDetail" required=""></textarea>
                      </div>

                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary">Confirm</button>
                </div>
                </form>
            </div>
        </div>
    </div>
  <!-- /Edit modal -->

  <!-- compare modal -->
  <div class="modal fade show" id="comparePop">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Compare Version</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">
                <div class="row">      
                      <div class="col-l border-right border-dark col-sm-6">

						<div class="form-group">
							<label>Current Version :</label>
							<div class="font-weight-bold">1.0.3</div>
						</div>

						<div class="form-group">
							<label>Type : </label>Private
						</div>

						<div class="form-group">
							<label>Title : </label>ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์
						</div>

						<div class="form-group">
							<label>Start Date : </label>	01-Jan-2020
						</div>

						<div class="form-group">
							<label>End Date : </label>	31-Dec-2020	
						</div>

						<div class="form-group">
							<label>Short Detail :</label>
							xxxxx
						</div>

						<div class="form-group">
							<label>Detail :</label>
							ตกลงยินยอมให้ บมจ.xxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า 
						</div>

                      </div>

					  <div class="col-r col-sm-6">
						
						<div class="form-group">
							<label>New Version :</label>
							<div class="font-weight-bold">1.0.4</div>
						</div>

						<div class="form-group">
							<label>Type : </label>Private
						</div>

						<div class="form-group">
							<label>Title : </label>ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์
						</div>

						<div class="form-group">
							<label>Start Date : </label>	01-Jan-2020
						</div>

						<div class="form-group">
							<label>End Date : </label>	31-Dec-2020	
						</div>

						<div class="form-group">
							<label>Short Detail :</label>
							xxxxx
						</div>

						<div class="form-group">
							<label>Detail :</label>
							ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า 
						</div>

					  </div>
                    
                </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
					<button type="button" class="btn btn-primary" data-dismiss="modal">Approve</button>
                </div>
                </form>
            </div>
        </div>
    </div>
	<!-- /compare modal -->

    </main>

  </div>
</template>
<script>

export default {
  name: 'Login',
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {

  },
  created () {
    
  },
  mounted () {
	  $('#tb_paging').DataTable( {
		searching: false,
		bLengthChange: true,
		destroy: true,
		info: true,
		"pagingType": "simple_numbers",
		//scrollCollapse: !0,
		//scrollX: true
	} );

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
