import Vue from 'vue'
import Vuex from 'vuex'
import UserModule from './user_module'
import AppModule from './app_module'
import ConsentModule from './consent_module'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
const modules = {
    user: UserModule,
    app: AppModule,
    consent: ConsentModule,
}

export default new Vuex.Store({
    modules,
    plugins: [
        createPersistedState({
            key: 'session_data',
            paths: ['user']
        })
    ]
})