<template >
  <div>
	<ConsentAddPop value="1" />
	<ConsentEditPop :consentId="consentId" value="2" />
    <main>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>Consent</h1>
						<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0">
							
								<li class="breadcrumb-item active text-gray" aria-current="page">Consent</li>
							</ol>
						</nav>
                    </div>


					<div class="srh-bar mb-4 d-flex justify-content-between flex-row flex-nowrap">
						<div class="col p-0 pl-1 pr-0">
							<a class="btn p-2 d-inline-block d-md-none" data-toggle="collapse" href="#searchOptions" role="button" aria-expanded="true" aria-controls="searchOptions">
								Display Options
								<i class="simple-icon-arrow-down align-middle"></i>
							</a>
							<div class="card collapse d-md-block" id="searchOptions">
								<div class="card-body p-3 d-flex flex-wrap justify-content-between h-100 align-items-end">
									<div class="d-flex flex-wrap w-100 mb-3">
									
										
										<div class="col-12 col-sm form-group mb-2">
											<label>Consent Title</label>
											<input v-model="consentTitle" type="text" class="form-control" />

										</div>

										<div class="col-12 col-sm form-group mb-2">
											<label>Consent Type</label>
											<select v-model="consentType"  class="form-control" data-width="100%">
												<option value="ALL">All Type</option>
												<option value="PUBLIC">Public</option>
												<option value="PRIVATE">Private</option>
											</select>

										</div>
										
										
									</div>

									<div class="d-flex flex-wrap w-100 align-items-end">

										<div class="col-12 col-sm form-group mb-2">
											<label>Consent Period</label>
											<div class="row mb-0 align-items-center">
												<div class="col pr-2">
													<div class="input-group">
														<!-- <span class="input-group-text input-group-append input-group-addon border-right-0">
															<i class="simple-icon-calendar"></i>
														</span>
														<input type="text" class="input-sm form-control border-left-0" name="start" placeholder="Start date"> -->
														<b-form-datepicker v-model="consentStartDate" id="datepicker-sm1" size="lg" locale="en" class="mb-2 form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
													</div>

												</div>
												<div class="font-weight-bold">To</div>
												<div class="col pl-2">
													<div class="input-group">
															<!-- <span class="input-group-text input-group-append input-group-addon border-right-0">
																<i class="simple-icon-calendar"></i>
															</span> -->
															<b-form-datepicker v-model="consentEndDate" id="datepicker-sm2" size="lg" locale="en" class="mb-2 form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
															<!-- <input type="text" class="input-sm form-control border-left-0" name="end" placeholder="End date"> -->
														</div>
												</div>
											</div>
										</div>
										
										<div class="col-12 col-sm form-group mb-2">
											<label>Consent Status</label>
											<select v-model="consentStatus" class="form-control" data-width="100%">
												<option value="ALL">All Status</option>
												<option value="D">Draft</option>
												<option value="A">Active</option>
												<option value="I">In-Active</option>
												<option value="E">Expired</option>
											</select>

										</div>


										<div class="top-right-button-container text-nowrap col-12 col-sm-auto mb-2">

											<button @click="search" class="btn btn-primary btn-md top-right-button rounded-05" type="button" id="btnSearch" style="min-width: 120px"> <!--<i class="icon-img"><img src="di/ic-search-wh.png" height="20"></i>--> Search</button>
										</div>
										
									</div>

								</div>
							</div>
						</div>

					</div>
					
					<div class="headbar-tb mb-4 d-flex justify-content-end align-items-center">
						<div class="col-r top-right-button-container d-flex align-items-end">
							<a href="javascript:;" @click="showAddPop" class="btn btn-primary btn-md top-right-button  mr-1">+ Add Consent</a>
							<!-- <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#consentAddPop" class="btn btn-primary btn-md top-right-button mr-1">+ Add Consent</a> -->
						</div>
					</div>

                    <div class="separator mb-5"></div>

					
					<div class="card main-consent-setting">
						<div class="card-body">
							<!-- <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
							
								<table id="tb_paging" class="responsive nowrap">
								
										<thead>
											<tr>
												<th>No.</th>
												<th>Type</th>
												<th>Title</th>
												<th>Consent Start Date</th>
												<th>Consent End Date</th>
												<th>Current Version</th>
												<th>Last Update</th>
												<th class="text-center sort-none">Status</th>
												<th class="text-center sort-none" width="20%">Action</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>1</td>
												<td>Private</td>
												<td>ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์</td>
												<td>01-Jan-2020</td>
												<td>31-Dec-2020</td>
												<td>1.0.3</td>
												<td>31-Aug-2020 17:17:10</td>
												<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
												<td class="text-center">
													<a href="/consent-detail" class="btn btn-primary mr-2 btn-sm">Detail</a>
													<a href="javascript:;" @click="showEditPop('1')" class="btn btn-primary mr-2 btn-sm">Edit</a>
													<a href="#" class="btn btn-light btn-sm">Delete</a>
												</td>
											</tr>

											<tr>
												<td>2</td>
												<td>Private</td>
												<td>ขอเก็บข้อมูลลูกค้าสินเชื่อรถจักรยานยนต์</td>
												<td>01-Jan-2020</td>
												<td>31-Dec-2020</td>
												<td>1.0.0</td>
												<td>01-Aug-2020 17:17:10</td>
												<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
												<td class="text-center">
													<a href="/consent-detail" class="btn btn-primary mr-2 btn-sm">Detail</a>
													<a href="javascript:;" @click="showEditPop('1')" class="btn btn-primary mr-2 btn-sm">Edit</a>
													<a href="#" class="btn btn-light btn-sm">Delete</a>
												</td>
											</tr>

											<tr>
												<td>3</td>
												<td>Private</td>
												<td>ขอเก็บข้อมูลลูกค้าสินเชื่อเงินสด</td>
												<td>01-Jan-2020</td>
												<td>31-Mar-2020</td>
												<td>1.1.3</td>
												<td>12-Sep-2020 17:17:10</td>
												<td class="text-center"><span class="text-danger font-weight-semibold">Expired</span></td>
												<td class="text-center">
													<a href="/consent-detail" class="btn btn-primary mr-2 btn-sm">Detail</a>
													<a href="javascript:;" @click="showEditPop('1')" class="btn btn-primary mr-2 btn-sm">Edit</a>
													<a href="#" class="btn btn-light btn-sm">Delete</a>
												</td>
											</tr>

											<tr>
												<td>4</td>
												<td>Private</td>
												<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์</td>
												<td>01-Jan-2020</td>
												<td>31-Dec-2020</td>
												<td>2.2.33</td>
												<td>31-Aug-2020 17:27:10</td>
												<td class="text-center"><span class="text-danger font-weight-semibold">In-Active</span></td>
												<td class="text-center">
													<a href="/consent-detail" class="btn btn-primary mr-2 btn-sm">Detail</a>
													<a href="javascript:;" @click="showEditPop('1')" class="btn btn-primary mr-2 btn-sm">Edit</a>
													<a href="#" class="btn btn-light btn-sm">Delete</a>
												</td>
											</tr>

											<tr>
												<td>5</td>
												<td>Private</td>
												<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถหาย</td>
												<td>01-Jan-2020</td>
												<td>31-Dec-2020</td>
												<td> N/A </td>
												<td> N/A </td>
												<td class="text-center"><span class="text-warning font-weight-semibold">Draft</span></td>
												<td class="text-center">
													<a href="/consent-detail" class="btn btn-primary mr-2 btn-sm">Detail</a>
													<a href="javascript:;" @click="showEditPop('1')" class="btn btn-primary mr-2 btn-sm">Edit</a>
													<a href="#" class="btn btn-danger btn-sm">Delete</a>
												</td>
											</tr>


											<tr>
												<td>6</td>
												<td>Public</td>
												<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถโดยสารทั่วไป</td>
												<td>01-Jan-2020</td>
												<td>31-Dec-2020</td>
												<td>9.2.33</td>
												<td>31-Aug-2020 17:17:10</td>
												<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
												<td class="text-center">
													<a href="/consent-detail" class="btn btn-primary mr-2 btn-sm">Detail</a>
													<a href="javascript:;" @click="showEditPop('1')" class="btn btn-primary mr-2 btn-sm">Edit</a>
													<a href="#" class="btn btn-light btn-sm">Delete</a>
												</td>
											</tr>

										</tbody>
								</table>
									


							</div> -->

							<b-container fluid>
								<!-- User Interface controls -->
								

								<!-- Main table element -->
								<b-table
									ref="table" 
									show-empty
									medium
									class="responsive nowrap"
									stacked="md"
									:items="dataProvider"
									:fields="fields"
									:current-page="currentPage"
									:per-page="perPage"
									:filter="filter"
									:filter-included-fields="filterOn"
									:sort-by.sync="sortBy"
									:sort-desc.sync="sortDesc"
									:sort-direction="sortDirection"
									@filtered="onFiltered"
								>
									<template #cell(consentStatus)="row">
										<span v-if="row.value == 'A'" class="text-primary font-weight-semibold">Active</span>
										<span v-if="row.value == 'D'" class="text-warning font-weight-semibold">Draft</span>
										<span v-if="row.value == 'I'" class="text-danger font-weight-semibold">In-Active</span>
										<span v-if="row.value == 'E'" class="text-danger font-weight-semibold">In-Active</span>
										<span v-if="row.value == 'C'" class="text-danger font-weight-semibold">Cancel</span>
										<span v-if="row.value == 'T'" class="text-danger font-weight-semibold">Terminated</span>
										<span v-if="row.value == 'WA'" class="text-info font-weight-semibold">Waiting for Approve</span>
										<span v-if="row.value == 'WP'" class="text-info font-weight-semibold">Waiting for Publish</span>
										<span v-if="row.value == 'WC'" class="text-info font-weight-semibold">Waiting for Cancel</span>
									</template>

									<template #cell(actions)="row">
										<!-- <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1 btn btn-primary mr-2 btn-sm">
										Info modal
										</b-button>
										<b-button size="sm" @click="row.toggleDetails" class="btn btn-primary mr-2 btn-sm">
										{{ row.detailsShowing ? 'Hide' : 'Show' }} Details
										</b-button> -->
										
										<a href="/consent-detail" class="btn btn-primary mr-2 btn-sm">Detail</a>
										<a href="javascript:;" @click="showEditPop(row.item.consentId)" class="btn btn-primary mr-2 btn-sm">Edit</a>
										<a href="javascript:;" @click="removeConsent(row.item.consentId)" class="btn btn-danger btn-sm" v-if="row.item.consentStatus === 'D' && checkPrivilege('CONSENT_DELETE') === true">Delete</a>
										<button class="btn btn-outline-danger btn-sm" v-if="row.item.consentStatus !== 'D' && checkPrivilege('CONSENT_DELETE') === true">Delete</button>
									</template>

									<template #row-details="row">
										<b-card>
										<ul>
											<li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
										</ul>
										</b-card>
									</template>
								</b-table>
								<b-row>
									

									<b-col sm="3" md="3" class="my-1">
										<b-form-group
										label="Per page"
										label-cols-sm="6"
										label-cols-md="4"
										label-cols-lg="3"
										label-align-sm="right"
										label-size="xs"
										label-for="perPageSelect"
										class="mb-0"
										>
											<b-form-select
												v-model="perPage"
												id="perPageSelect"
												size="xs"
												:options="pageOptions"
											>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col sm="6" md="6" class="my-1">

									</b-col>
									<b-col sm="3" md="3" class="my-1">
										<b-pagination
										v-model="currentPage"
										:total-rows="totalRows"
										:per-page="perPage"
										align="fill"
										size="sm"
										class="my-0"
										>
										</b-pagination>
									</b-col>
								</b-row>
								<!-- Info modal -->
								<b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
									<pre>{{ infoModal.content }}</pre>
								</b-modal>
							</b-container>
							
							
						</div>
					</div>
                </div>
            </div>
        </div>

    </main>


  </div>
</template>
<script>
import ConsentAddPop from './popup/ConsentAddPop.vue'
import ConsentEditPop from './popup/ConsentEditPop.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'ConsentList',
  data () {
    return {
		consentTitle: null,
		consentType: null,
		consentStatus: null,
		consentStartDate: null,
		consentEndDate: null,
		modalOpen: false,
		consentId: null,
		items: [
        ],
        fields: [
		  { key: 'consentId', label: 'Consent ID', sortable: true, sortDirection: 'desc' },
		  { key: 'consentType', label: 'Consent Type', sortable: true, sortDirection: 'desc' },
		  { key: 'consentTitle', label: 'Title', sortable: true, sortDirection: 'desc' },
		  { key: 'consentStartDate', label: 'Start Date', sortable: true, sortDirection: 'desc' },
		  { key: 'consentEndDate', label: 'End Date', sortable: true, sortDirection: 'desc' },
		  { key: 'currentVersion', label: 'Current Version', sortable: true, sortDirection: 'desc' },
		  { key: 'consentLastUpdate', label: 'Last Update', sortable: true, sortDirection: 'desc' },
		  { key: 'consentStatus', label: 'Status', sortable: true, sortDirection: 'desc' },
          //{ key: 'age', label: 'Person age', sortable: true, class: 'text-center' },
          
          { key: 'actions', label: 'Actions' }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 20, 30],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
    }
  },
  components: {
	  ConsentAddPop,
	  ConsentEditPop,
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
	...mapGetters({
	  consentEdit: 'consent/getConsentEdit',
	  getPrivileges: 'user/getPrivileges'
	}),
	sortOptions() {
	// Create an options list from our fields
	return this.fields
		.filter(f => f.sortable)
		.map(f => {
		return { text: f.label, value: f.key }
		})
	}
  },
  methods: {
	...mapActions({
		setConsentEdit: 'consent/setConsentEdit',
		listConsent: 'consent/listConsent',
		deleteConsent: 'consent/deleteConsent',
	}),
	checkPrivilege: function(privilege) {
      return this.getPrivileges.includes(privilege)
    },
    // setConsentEdit(data){
    //    // alert('ok');
    //    this.setConsentEdit(data)
	// },
	showAddPop() {
	  //	this.$modal.show(ConsentAddPop, {})
	  this.$bvModal.show('modal-consent-add')	
	},
	//showEditPop() {
	showEditPop: async function (item) {
		//this.isModalVisible = true;
	//	alert(item)
		this.consentId = item
		this.setConsentEdit(item)
		this.$bvModal.show('modal-consent-edit')	
	},
	removeConsent(consentId){
		//alert(consentId);
		let _this = this
		this.$bvModal.msgBoxConfirm('กรุณากดปุ่มยืนยันเพื่อดำเนินการลบข้อมูล เมื่อยืนยันแล้วจะไม่สามารถเรียกดูข้อมูลนี้ได้อีกต่อไป', {
			title: 'Warning',
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'danger',
			cancelVariant: 'primary',
			okTitle: 'ยืนยัน',
			cancelTitle: 'ยกเลิก',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true
		}).then(async value => {
			if(value){
				let data = {
					consent_id: consentId
				}
				let response = await _this.deleteConsent(data);
				console.log('response =====> ',response)
				if(response.statuscode == 0){
					_this.showError('ทำรายการสำเร็จ', 'ลบข้อมูลเรียบร้อย');
					_this.search();
				}else{
					_this.showError('Error', response.statusdesc);
				}
			}
		})
		.catch(err => {
			
		})
	},
	closeModal() {
		this.isModalVisible = false;
	},
	showError(title, message){
      this.$bvModal.msgBoxOk(message, {
        title: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      }).then(value => {
        
      })
      .catch(err => {
        // An error occurred
      })
    },
	info(item, index, button) {
		this.infoModal.title = `Row index: ${index}`
		this.infoModal.content = JSON.stringify(item, null, 2)
		this.$root.$emit('bv::show::modal', this.infoModal.id, button)
	},
	resetInfoModal() {
		this.infoModal.title = ''
		this.infoModal.content = ''
	},
	onFiltered(filteredItems) {
		// Trigger pagination to update the number of buttons/pages due to filtering
		this.totalRows = filteredItems.length
		this.currentPage = 1
	},
	search() {
	  //	this.$modal.show(ConsentAddPop, {})
	  this.$refs.table.refresh();
	},
	dataProvider: async function(ctx) {
		try {
			console.log('reload ++++', this.consentStartDate, this.consentEndDate)
			let data = {
				// consent_enddate: "2020-01-01 7:47:40",
				// consent_startdate: "2020-12-01T07:47:40",
				consent_status: this.consentStatus,
				consent_title: this.consentTitle,
				consent_type: this.consentType,
				item_per_page: 10,
				page: 1
			}
			let ret = []
			let response = await this.listConsent(data)
			console.log('consent : ', response)
			if(response.statuscode == 0){
				this.totalRows = response.total_items
				for(let idx in response.data){
					let consent = response.data[idx]
					console.log(consent)
					ret.push({ 
						consentId: consent.consent_id, 
						consentType: consent.consent_type, 
						consentTitle: consent.consent_title_th, 
						consentStartDate: consent.consent_startdate, 
						consentEndDate: consent.consent_enddate, 
						currentVersion: consent.current_version != null ? consent.current_version : 'N/A', 
						consentLastUpdate: consent.updated_datetime != null ? consent.updated_datetime : 'N/A', 
						consentStatus: consent.consent_status, 
						
						
					})
				}
			}
			//const response = await axios.get(`/some/url?page=${ctx.currentPage}&size=${ctx.perPage}`)

			return ret
		} catch (error) {
			return []
		}
	}
  },
  created () {
    
  },
  async mounted () {
	
	// $('#tb_paging').DataTable( {
	// 	searching: false,
	// 	bLengthChange: true,
	// 	destroy: true,
	// 	info: true,
	// 	"pagingType": "simple_numbers",
	// 	//scrollCollapse: !0,
	// 	//scrollX: true
	// } );
	this.totalRows = this.items.length

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
