<template >
  <div>
    <main>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>Consent Group</h1>
						<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0">
								<!--<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>-->
								<li class="breadcrumb-item">
									<a href="/superconsetn-list">Consent Group</a>
								</li>
								<li class="breadcrumb-item active text-gray" aria-current="page">View</li>
							</ol>
						</nav>

            </div>

          <div class="mb-2 d-flex justify-content-between align-items-center">
						<div class="col-l">
							<h2 class="sub-head font-weight-bold text-medium mb-0">Info</h2>
						</div>
						
					</div>

          <div class="card mb-4">
            <div class="card-body">
              <div class="row mb-0">
                  <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Channel:</p>
                    <p>
                      Chatbot
                    </p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Current Version:</p>
                    <p>
                      10.1.3
                    </p>
                  </div>
              </div>

              <div class="row mb-0">

                  <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Consent Group Title (TH):</p>
                    <p>
                      	กลุ่มข้อตกลงขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Consent Group Title (EN):</p>
                    <p>
                      	กลุ่มข้อตกลงขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์
                    </p>
                  </div>
                  <!-- <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Consent Group Type:</p>
                    <p>
                      Private
                    </p>
                  </div> -->
              </div>


              <div class="row mb-0">
                  <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Start Date:</p>
                    <p>
                      01-Jan-2020
                    </p>
                  </div>
                  <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Expire Date:</p>
                    <p>
                      31-Dec-2020	
                    </p>
                  </div>
              </div>

              <div class="row mb-0">
                  <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Detail of Consent Group (TH):</p>
                    <p>
                      ตกลงยินยอมให้ บมจ.xxxx  เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า
                    </p>
                  </div>

                  <div class="col-sm-6">
                    <p class="text-muted text-small mb-1">Detail of Consent Group (EN):</p>
                    <p>
                      ตกลงยินยอมให้ บมจ.xxxx  เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า
                    </p>
                  </div>
                  
              </div>
              <div class="row mb-0">
                  
                </div>



            </div>
          </div>

          <div class="mb-2 d-flex justify-content-between align-items-center">
						<div class="col-l">
							<h2 class="sub-head font-weight-bold text-medium mb-0">Version</h2>
						</div>
						<div class="col-r pb-2 top-right-button-container d-flex align-items-end">
              <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#consentAddPop" class="btn btn-primary btn-md top-right-button mr-1">+ Add Version</a>
						</div>
					</div>


					<div class="card main-consent-setting">
					<div class="card-body">
						<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
							<!--<table id="tablelist" class="data-table data-table-feature">-->
							<!--<table id="tablelist" class="data-table data-table-standard">-->
              <!-- <table id="tb_paging" class="responsive nowrap">
							
									<thead>
										<tr>
											<th class="text-center pr-0">No.</th>
											<th>Version</th>
											<th class="text-center pr-0">Status</th>
											<th class="text-center sort-none" width="25%">Action</th>
										</tr>
									</thead>
									<tbody>

                    <tr>
											<td class="text-center">3</td>
											<td>
												<a class="text-dark" href="#">
													1.0.3													
												</a>
											</td>
											<td class="text-center"><span class="text-warning font-weight-semibold">Waiting</span></td>
											<td class="text-center">
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerViewPop" class="btn btn-primary btn-sm mr-2">View</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Edit</a> 
                        <a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>

                    <tr>
											<td class="text-center">2</td>
											<td>
												<a class="text-dark" href="#">
													1.0.2													
												</a>
											</td>
											<td class="text-center"><span class="text-danger font-weight-semibold">Reject</span></td>
											<td class="text-center">
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerViewPop" class="btn btn-primary btn-sm mr-2">View</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Edit</a> 
                        <a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>


										<tr>
											<td class="text-center">1</td>
											<td>
												<a class="text-dark" href="#">
													1.0.1													
												</a>
											</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerViewPop" class="btn btn-primary btn-sm mr-2">View</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Edit</a> 
                        <a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>


									</tbody>
								</table> -->

                <table id="tb_paging" class="responsive nowrap">
							
									<thead>
										<tr>
											<th class="text-center">No.</th>
											<th>Consent Version</th>
                      <th>Created Datetime</th>
                      <th>Terminated Datetime</th>
											<th class="text-center pr-0">Status</th>
											<th class="text-center sort-none" width="25%">Action</th>
										</tr>
									</thead>
									<tbody>

										<tr>
											<td class="text-center">1</td>
											<td>1.0.1</td>
                      <td>01-Jan-2020 14:22:11</td>
											<td>31-Dec-2020 23:59:59</td>
											<td class="text-center"><span class="text-danger font-weight-semibold">Terminated</span></td>
											<td class="text-center">
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#superConsentCopyPopUp" class="btn btn-primary btn-sm mr-2">Copy</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerViewPop" class="btn btn-primary btn-sm mr-2">Detail</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-light btn-sm mr-2">Edit</a> 
                        <a href="javascript:;" class="btn btn-light btn-sm">Delete</a>
											</td>
										</tr>

                    <tr>
											<td class="text-center">2</td>
											
											<td>1.0.2</td>
                      <td>30-Aug-2020 14:14:11</td>
											<td>N/A</td>
											<td class="text-center"><span class="text-danger font-weight-semibold">Reject</span></td>
											<td class="text-center">
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#superConsentCopyPopUp" class="btn btn-primary btn-sm mr-2">Copy</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerViewPop" class="btn btn-primary btn-sm mr-2">Detail</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-light btn-sm mr-2">Edit</a> 
                        <a href="javascript:;" class="btn btn-light btn-sm">Delete</a>
											</td>
										</tr>


                    <tr>
											<td class="text-center">3</td>
											<td>1.0.3</td>
                      <td>01-Sep-2020 19:00:11</td>
											<td>N/A</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#superConsentCopyPopUp" class="btn btn-primary btn-sm mr-2">Copy</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerViewPop" class="btn btn-primary btn-sm mr-2">Detail</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-light btn-sm mr-2">Edit</a> 
                        <a href="javascript:;" class="btn btn-light btn-sm">Delete</a>
											</td>
										</tr>


                    <tr>
											<td class="text-center">4</td>
											<td>1.0.4</td>
                      <td>09-Sep-2020 04:10:11</td>
											<td>N/A</td>
											<td class="text-center"><span class="text-warning font-weight-semibold">Wait for approve</span></td>
											<td class="text-center">
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#superConsentCopyPopUp" class="btn btn-primary btn-sm mr-2">Copy</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerViewPop" class="btn btn-primary btn-sm mr-2">Detail</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-light btn-sm mr-2">Edit</a> 
                        <a href="javascript:;" class="btn btn-light btn-sm">Delete</a>
											</td>
										</tr>


                    <tr>
											<td class="text-center">5</td>
											<td>1.0.5</td>
                      <td>09-Sep-2020 04:10:11</td>
											<td>N/A</td>
											<td class="text-center"><span class="text-warning font-weight-semibold">Draft</span></td>
											<td class="text-center">
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#superConsentCopyPopUp" class="btn btn-primary btn-sm mr-2">Copy</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerViewPop" class="btn btn-primary btn-sm mr-2">Detail</a> 
                        <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Edit</a> 
                        <a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>


									</tbody>
								</table>
								
		

						</div>
					</div>
					</div>
                </div>
            </div>
        </div>

    </main>

    <!-- Add modal -->
    <div class="modal fade show" id="consentAddPop">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add New Version</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">
                    <div class="row">
                      <div class="col-sm-12 form-group cv-select-cr">
                        <label>Version :</label>
                        <input type="text" class="form-control" value="">
                      </div>

                      <div class="col-sm-6 form-group">
                        <label>Detail (TH) :</label>
                        <textarea class="form-control" rows="7" name="jQueryDetail" required="">ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า </textarea>
                      </div>
                      
                      <div class="col-sm-6 form-group">
                          <label>Detail (EN) :</label>
                          <textarea class="form-control" rows="7" name="jQueryDetail" required="">ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า </textarea>
                        </div>

                        <div class="col-sm-12 form-group cv-select-cr">
                        <label>Consent :</label>
                        <input type="text" class="form-control" value="">

                         <div class="custom-control mb-0">
                              
                              <label>consent 1 (Public)</label>
                          </div>
                           <div class="custom-control mb-0">
                              
                              <label>consent 2 (Private) </label>
                          </div>
                           <div class="custom-control mb-0">
                              
                              <label>consent 3 (Public) </label>
                          </div>
                      </div>

                     
                </div>    
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary">Preview</button>
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                    
                    <button type="button" class="btn btn-primary">Submit</button>
                </div>
                </form>
            </div>
        </div>
    </div>
  <!-- /Edit modal -->

    <!-- Edit modal -->
    <div class="modal fade show" id="customerEditPop">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Version</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">
                <div class="row">    
                      <div class="col-12 form-group cv-select-cr">
                        <label>Version :</label>
                        1.0.1
                      </div>

                      <div class="col-sm-6 form-group">
                        <label>Detail (TH) :</label>
                        <textarea class="form-control" rows="7" name="jQueryDetail" required="">ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า </textarea>
                      </div>
                      
                      <div class="col-sm-6 form-group">
                          <label>Detail (EN) :</label>
                          <textarea class="form-control" rows="7" name="jQueryDetail" required="">ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า </textarea>
                        </div>
                    
                    <div class="col-sm-6 form-group">
                          <label>Private Consents :</label><br />
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private1CS1" checked>
                              <label class="custom-control-label" for="Private1CS1">consent 1 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private1CS2" checked>
                              <label class="custom-control-label" for="Private1CS2">consent 2 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private1CS3" checked>
                              <label class="custom-control-label" for="Private1CS3">consent 3 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private1CS4">
                              <label class="custom-control-label" for="Private1CS4">consent 4 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private1CS5" checked>
                              <label class="custom-control-label" for="Private1CS5">consent 5 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private1CS6">
                              <label class="custom-control-label" for="Private1CS6">consent 6 </label>
                          </div>
                        </div>

                      <div class="col-sm-6 form-group">
                          <label>Public Consents :</label><br />
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Public1CS1" checked>
                              <label class="custom-control-label" for="Public1CS1">Public consent 1 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Public1CS2">
                              <label class="custom-control-label" for="Public1CS2">Public consent 2 </label>
                          </div>
                          
                        </div>

                </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary">Submit</button>
                </div>
                </form>
            </div>
        </div>
    </div>
  <!-- /Edit modal -->


    <!-- Edit modal -->
    <div class="modal fade show" id="customerViewPop">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">View Version</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">
                <div class="row">    
                      <div class="col-12 form-group cv-select-cr">
                        <label>Version :</label>
                        1.0.1
                      </div>

                      <div class="col-sm-6 form-group">
                        <label>Detail (TH) :</label>
                        ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า 
                      </div>
                      
                      <div class="col-sm-6 form-group">
                          <label>Detail (EN) :</label>
                          ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า 
                        </div>

                         <div class="col-sm-6 form-group">
                          <label>Private Consents :</label><br />
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private2CS1" checked>
                              <label class="custom-control-label" for="Private2CS1">consent 1 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private2CS2" checked>
                              <label class="custom-control-label" for="Private2CS2">consent 2 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private2CS3" checked>
                              <label class="custom-control-label" for="Private2CS3">consent 3 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private2CS4">
                              <label class="custom-control-label" for="Private2CS4">consent 4 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private2CS5" checked>
                              <label class="custom-control-label" for="Private2CS5">consent 5 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private2CS6">
                              <label class="custom-control-label" for="Private2CS6">consent 6 </label>
                          </div>
                        </div>

                      <div class="col-sm-6 form-group">
                          <label>Public Consents :</label><br />
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Public2CS1" checked>
                              <label class="custom-control-label" for="Public2CS1">Public consent 1 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Public2CS2">
                              <label class="custom-control-label" for="Public2CS2">Public consent 2 </label>
                          </div>
                        </div>
                    
                </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary">Submit</button>
                </div>
                </form>
            </div>
        </div>
    </div>
  <!-- /Edit modal -->

  <div class="modal fade show" id="superConsentCopyPopUp">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Copy Version</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">
                <div class="row">    
                      <div class="col-sm-6 form-group cv-select-cr">
                        <label>Copy From Version :</label>
                        <div class="p-2 bg-light font-bold">1.0.1</div>
                      </div>

                      <div class="col-sm-6 form-group cv-select-cr">
                        <label>Version :</label>
                        <input type="text" class="form-control" value="">
                      </div>

                      <div class="col-sm-6 form-group">
                        <label>Detail (TH) :</label>
                        <textarea class="form-control" rows="7" name="jQueryDetail" required="">ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า </textarea>
                      </div>
                      
                      <div class="col-sm-6 form-group">
                          <label>Detail (EN) :</label>
                          <textarea class="form-control" rows="7" name="jQueryDetail" required="">ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า </textarea>
                        </div>
                    
                    <div class="col-sm-6 form-group">
                          <label>Private Consents :</label><br />
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private3CS1" checked>
                              <label class="custom-control-label" for="Private3CS1">consent 1 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private3CS2" checked>
                              <label class="custom-control-label" for="Private3CS2">consent 2 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private3CS3" checked>
                              <label class="custom-control-label" for="Private3CS3">consent 3 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private3CS4">
                              <label class="custom-control-label" for="Private3CS4">consent 4 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private3CS5" checked>
                              <label class="custom-control-label" for="Private3CS5">consent 5 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Private3CS6">
                              <label class="custom-control-label" for="Private3CS6">consent 6 </label>
                          </div>
                        </div>

                      <div class="col-sm-6 form-group">
                          <label>Public Consents :</label><br />
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Public3CS1" checked>
                              <label class="custom-control-label" for="Public3CS1">Public consent 1 </label>
                          </div>
                          <div class="custom-control custom-checkbox mb-0">
                              <input type="checkbox" class="custom-control-input" id="Public3CS2">
                              <label class="custom-control-label" for="Public3CS2">Public consent 2 </label>
                          </div>
                        </div>

                </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary">Copy</button>
                </div>
                </form>
            </div>
        </div>
    </div>


  </div>
</template>
<script>

export default {
  name: 'Login',
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {

  },
  created () {
    
  },
  mounted () {
    $('#tb_paging').DataTable( {
		searching: false,
		bLengthChange: false,
		destroy: true,
		info: true,
		"pagingType": "simple_numbers",
		//scrollCollapse: !0,
		//scrollX: true
	} );

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
