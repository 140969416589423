import HttpRequest from './http_request'

class UserService extends HttpRequest {
    async login(data) {
        try {
            return this.post('/authenticate', data)
        } catch (e) {
            console.error(e)
            return null
        }
    }
}

export default UserService