<template >
  <div>
    <main>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>Account</h1>
						<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0">
								<!--<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>
								<li class="breadcrumb-item">
									<a href="#">Library</a>
								</li>-->
								<li class="breadcrumb-item active text-gray" aria-current="page">Account </li>
							</ol>
						</nav>

                    </div>

                    <!-- <div class="mb-2 d-flex justify-content-between">
						<div class="col-l">
							<a class="btn pt-0 pl-0 d-inline-block d-md-none" data-toggle="collapse" href="#displayOptions"
								role="button" aria-expanded="true" aria-controls="displayOptions">
								Display Options
								<i class="simple-icon-arrow-down align-middle"></i>
							</a>
							<div class="collapse d-md-block" id="displayOptions">
								<div class="d-flex align-items-end">
                  <div class="float-md-left mr-1 mb-1">
										<label class="d-block">Customer Name :</label>
										<input type="text" class="form-control form-control-sm bg-white rounded-2" name="UserName" placeholder="">
									</div>
                  
                  <div class="float-md-left mr-1 mb-1 dropdown-as-select">
										<label class="d-block">Customer Type :</label>
										<button class="btn bg-white btn-outline-dark btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											All Type
										</button>
										<div class="dropdown-menu">
											<a class="dropdown-item active" href="#">All Role</a>
											<a class="dropdown-item" href="#">1</a>
                      <a class="dropdown-item" href="#">2</a>
                      <a class="dropdown-item" href="#">3</a>
                      <a class="dropdown-item" href="#">4</a>
										</div>
									</div>

									<div class="float-md-left mr-1 mb-1">
										
										<button class="btn btn-primary btn-xs text-white" type="button">
											Search
										</button>
										
									</div>
									

								</div>
							</div>
						</div>
						<div class="col-r pb-2 top-right-button-container d-flex align-items-end">
                            <a href="/customer-add" class="btn btn-primary btn-md top-right-button mr-1">+ Add Customer</a>
								
						</div>
					</div> -->

					<div class="srh-bar mb-4 d-flex justify-content-between flex-row flex-nowrap">
						<div class="col p-0 pl-1 pr-0">
							<a class="btn p-2 d-inline-block d-md-none" data-toggle="collapse" href="#searchOptions" role="button" aria-expanded="true" aria-controls="searchOptions">
								Display Options
								<i class="simple-icon-arrow-down align-middle"></i>
							</a>
							<div class="card collapse d-md-block" id="searchOptions">
								<div class="card-body p-3 d-flex flex-wrap justify-content-between h-100 align-items-end">
									<div class="d-flex flex-wrap w-100 mb-3">
									
										

										<!-- <div class="col-12 col-sm form-group mb-2">
											<label>Account Type</label>
											<select class="form-control select2-single" data-width="100%" data-placeholder="Select Consent Status" data-minimum-results-for-search="Infinity">
												<option>All Type</option>
												<option>Customer</option>
												<option>Lead</option>
											</select>

										</div> -->

										<div class="col-4 col-sm form-group mb-2">
											<label>Account ID Card</label>
											<input type="text" class="form-control" />
										</div>

										<div class="col-4 col-sm form-group mb-2">
											<label>Account Name</label>
											<input type="text" class="form-control" />

										</div>
										<div class="col-4 col-sm form-group mb-2">
											<label>Account Phone</label>
											<input type="text" class="form-control" />
										</div>
										
									</div>

									<div class="d-flex flex-wrap w-100 align-items-end">

										<!-- <div class="col-12 col-sm form-group mb-2">
											<label>Account ID Card</label>
											<input type="text" class="form-control" />
										</div> -->
										
										<!-- <div class="col-12 col-sm form-group mb-2">
											<label>Account Phone</label>
											<input type="text" class="form-control" />
										</div> -->

										<div class="col-12 col-sm form-group mb-2">

										</div><div class="col-12 col-sm form-group mb-2">

										</div>



										<div class="top-right-button-container text-nowrap col-12 col-sm-auto mb-2">

											<button class="btn btn-primary btn-md top-right-button rounded-05" type="button" id="btnSearch" style="min-width: 120px"> <!--<i class="icon-img"><img src="di/ic-search-wh.png" height="20"></i>--> Search</button>
										</div>
										
									</div>

								</div>
							</div>
						</div>

					</div>
					
					<div class="headbar-tb mb-4 d-flex justify-content-end align-items-center">
						<div class="col-r top-right-button-container d-flex align-items-end">
							<a href="/customer-add" class="btn btn-primary btn-md top-right-button  mr-1">+ Add Customer</a>
							<!-- <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#consentAddPop" class="btn btn-primary btn-md top-right-button mr-1">+ Add Consent</a> -->
						</div>
					</div>

                    <div class="mb-3"></div>
					<div class="card main-consent-setting">
					<div class="card-body">
						<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
							<!--<table id="tablelist" class="data-table data-table-feature">-->
							<!--<table id="tablelist" class="data-table data-table-standard">-->
                           <!-- <table class="data-table data-tables-pagination responsive nowrap"> -->
							   <table id="tb_paging" class="responsive nowrap">
							
									<thead>
										<tr>
											<th class="text-center" width="10%">Consent Account No.</th>
                      						<th>ID Card</th>
                      						<th>Phone</th>
											<th>Name</th>
											<th class="text-center pr-0" width="15%">Status</th>
											<th class="text-center sort-none" width="25%">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td class="text-center">000001</td>
											  <td>376545567XXXX</td>
                      						  <td>081-111-2222</td>
											  <td>Sarawut P</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
                      							<a href="/customer-view" class="btn btn-primary btn-sm mr-2">Detail</a> 
												<a href="/customer-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
												<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
										<tr>
											<td class="text-center">000002</td>
                      						<td>372545567XXXX</td>
											<td>081-111-2220</td>
											<td>Emma P</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
                      							<a href="/customer-view" class="btn btn-primary btn-sm mr-2">Detail</a> 
												<a href="/customer-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
												<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
										<tr>
											<td class="text-center">000003</td>
                      						<td>376505567XXXX</td>
											<td>089-111-2222</td>
											<td>hathai P</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
                      							<a href="/customer-view" class="btn btn-primary btn-sm mr-2">Detail</a> 
												<a href="/customer-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
												<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
										<tr>
											<td class="text-center">000004</td>
                      						<td>376545567XXXX</td>
											<td>091-111-2222</td>
											<td>Sri P</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
                      							<a href="/customer-view" class="btn btn-primary btn-sm mr-2">Detail</a> 
												<a href="/customer-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
												<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
										<tr>
											<td class="text-center">000005</td>
                      						<td>37054556789XX</td>
											<td>081-311-2222</td>
											<td>Andrews P</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
                      							<a href="/customer-view" class="btn btn-primary btn-sm mr-2">Detail</a> 
												<a href="/customer-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
												<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
                    
									</tbody>
								</table>
								
		

						</div>
					</div>
					</div>
                </div>
            </div>
        </div>


    </main>

  </div>
</template>
<script>

export default {
  name: 'Login',
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {

  },
  created () {
    
  },
  mounted () {

	   $('#tb_paging').DataTable( {
            searching: false,
            bLengthChange: true,
            destroy: true,
            info: true,
            "pagingType": "simple_numbers",
            //scrollCollapse: !0,
            //scrollX: true
        } );

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
