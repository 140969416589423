<template >
  <div>
    <main>
    <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>Consent</h1>
						<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0">
								<!--<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>-->
								<li class="breadcrumb-item">
									<a href="/consent-list">consent</a>
								</li>
								<li class="breadcrumb-item active text-gray" aria-current="page">Edit Consent </li>
							</ol>
						</nav>
                    </div>
                    <div class="mb-3"></div>
					<div class="d-flex justify-content-center mb-4">
						<div class="col-sm-10 col-md-8 col-lg-6">
							<div class="card h-100 d-flex justify-content-center flex-row">
								<div class="card-body col-md-9 col-lg-8">
									
									<div class="wr mb-4">
										<label>Consent Type :</label>
										<select class="form-control select2-single" data-width="100%">
											<option value="public" selected>Private</option>
											<option value="public">Public</option>
										</select>
									</div>

									<div class="wr mb-4">
										<label>Consent Title (TH) :</label>
										<input type="text" class="form-control" value="NTL loan system management" />
									</div>
									
									<div class="wr mb-4">
										<label>Consent Title (EN) :</label>
										<input type="text" class="form-control" value="NTL loan system management" />
									</div>

									<!-- <div class="wr mb-4">
										<label>Short Description (TH):</label>
										<textarea class="form-control" rows="10" name="jQueryDetail" required=""></textarea>
									</div>

									<div class="wr mb-4">
										<label>Short Description (EN):</label>
										<textarea class="form-control" rows="10" name="jQueryDetail" required=""></textarea>
									</div> -->

									<div class="wr mb-4">
										<label>Detail (TH):</label>
										<textarea class="form-control" rows="10" name="jQueryDetail" required="">Detail........</textarea>
									</div>

									<div class="wr mb-4">
										<label>Detail (EN):</label>
										<textarea class="form-control" rows="10" name="jQueryDetail" required="">Detail........</textarea>
									</div>

									<div class="wr mb-4">
										<label>Start Date :</label>
										<input type="text" class="form-control datepicker" value="2020-02-02">
									</div>

									<div class="wr mb-4">
										<label>End Date :</label>
										<input type="text" class="form-control datepicker" value="2020-12-02">
									</div>

                  <div class="wr mb-4">
										<label>Consent Status :</label>
										<select class="form-control select2-single" data-width="100%">
											<option value="public" selected>Active</option>
											<option value="public">In-Active</option>
										</select>
									</div>
																
									<div class="d-flex justify-content-center mb-4">
										<a href="javascript:history.back();">
											<button type="button" class="btn btn-gray btn-sm mb-2 mr-1 min-w">Reset</button>
										</a>
										<button type="button" class="btn btn-primary btn-sm mb-2 ml-1 min-w">Save</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					
					
					
					
                </div>
            </div>
        </div>
  </main>

  </div>
</template>
<script>

export default {
  name: 'Login',
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {

  },
  created () {
    
  },
  mounted () {
    

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
