<template>
  <div>
    <b-button v-b-modal.modal-scrollable>Launch scrolling modal</b-button>
    <b-modal
      id="modal-consent-edit"
      scrollable
      title="Edit Consent"
      size="xl"
      @ok="editAction"
    >
      <div class="row">    
					<div class="col-sm-6 form-group">
						<label>Consent Title (TH) :</label>
						<input v-model="consentTitleTh" type="text" class="form-control" name="jQueryDetail" value="ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์	" />
						</div>
						
						<div class="col-sm-6 form-group">
							<label>Consent Title (EN) :</label>
							<input v-model="consentTitleEn" type="text" class="form-control" name="jQueryDetail" value="ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์	" />
						</div>

            <div class="col-sm-6 form-group">
              <label>Detail (TH) :</label>
              <textarea v-model="consentDetailTh" class="form-control" rows="7" name="jQueryDetail" required="">ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า</textarea>
            </div>
            
            <div class="col-sm-6 form-group">
                <label>Detail (EN) :</label>
                <textarea v-model="consentDetailEn" class="form-control" rows="7" name="jQueryDetail" required="">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</textarea>
              </div>

            <div class="col-sm-6 form-group">
            <label>Consent Start Date :</label> 
            <b-form-datepicker v-model="consentStartDate" id="datepicker-sm1" size="lg" locale="en" class="mb-2 form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
            </div>
            
            <div class="col-sm-6 form-group">
              <label>Consent End Date :</label>
              <b-form-datepicker v-model="consentEndDate" id="datepicker-sm2" size="lg" locale="en" class="mb-2 form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
            </div>

            <div v-if="consentStatus == 'A' || consentStatus == 'I' " class="col-12 form-group cv-select-cr">
              <label>Consent Status :</label>
              <select v-model="consentStatus" class="form-control" data-width="100%">
                <option value="A" selected>Active</option>
                <option value="I">In-Active</option>
              </select>
            </div>
      </div>    
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: "Modal",
  data() {
    return {
      consentType: 'PRIVATE',
      consentTitleTh: null,
      consentTitleEn: null,
      consentDetailTh: null,
      consentDetailEn: null,
      consentStartDate: null,
      consentEndDate: null,
      consentStatus: null,
    }
  },
  props: {
    value: {
      required: true,
    },
    consentId: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getPrivileges: 'user/getPrivileges'
	  }),
  },
  methods: {
    ...mapActions({
      hasPrivilege: 'user/hasPrivilege',
      modifyConsent: 'consent/modifyConsent',
      viewConsent: 'consent/viewConsent',
    }),
    checkPrivilege: function(privilege) {
      return this.getPrivileges.includes(privilege)
    },
    async editAction(bvModalEvt) {
      let data = {
        consent_id: this.consentId,
        consent_detail: this.consentDetailTh,
        consent_enddate: this.consentEndDate,
        consent_startdate: this.consentStartDate,
        consent_title_en: this.consentTitleEn,
        consent_title_th: this.consentTitleTh,
        consent_type: this.consentType,
        consent_status: this.consentStatus,
      }
      let response = await this.modifyConsent(data);
      if(response.statuscode == 0){
        this.showError('Success', response.statusdesc);
        this.$parent.search();
      }else{
        this.showError('Error', response.statusdesc);
      }
      // bvModalEvt.preventDefault();
      // alert(this.consentId);
    },
    showError(title, message){
      this.$bvModal.msgBoxOk(message, {
        title: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      }).then(value => {
        
      })
      .catch(err => {
        // An error occurred
      })
    },
    loadData: async function(consentId) {
      //alert(consentId);
      let consent = await this.viewConsent({consent_id: consentId})
      if(consent.statuscode == 0 && consent.data.length > 0){
        //consent.data.
        console.log(consent.data[0].consent_title_th)
        this.consentTitleTh = consent.data[0].consent_title_th
        this.consentTitleEn = consent.data[0].consent_title_en
        this.consentDetailTh = consent.data[0].consent_detail_th
        this.consentDetailEn = consent.data[0].consent_detail_en
        this.consentEndDate = consent.data[0].consent_startdate
        this.consentStartDate = consent.data[0].consent_enddate
        this.consentStatus = consent.data[0].consent_status

      }else{

      }
      console.log(consent)
    },
  },
  updated(){
    
  },
  watch: { 
    consentId: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.loadData(newVal)
      //alert(this.consentId);
    }
  }
};
</script>
