<template >
  <div>

      <main>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>Dashboards</h1>
                    </div>

                    <div class="mb-2 d-flex justify-content-between">
                        <div class="col-l">
                            <a class="btn pt-0 pl-0 d-inline-block d-md-none" data-toggle="collapse" href="#displayOptions" role="button" aria-expanded="true" aria-controls="displayOptions">
                Display Options
                <i class="simple-icon-arrow-down align-middle"></i>
              </a>
                            <div class="collapse d-md-block" id="displayOptions">
                                <div class="d-flex align-items-end">

                                    <div class="float-md-left mr-2 mb-1 dropdown-as-select">

                                        <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Select display date : Today
                    </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item active" href="#">Select display date : Today</a>
                                            <a class="dropdown-item" href="#">Another action</a>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <!--<div class="col-r pb-2 top-right-button-container d-flex align-items-end">
                            <button type="button" class="btn bg-green btn-lg top-right-button btn-export mr-1"><i class="glyph-icon iconsminds-receipt-4"></i> Export  Excel</button>

						</div>-->
                    </div>

                    <div class="separator mb-5"></div>

                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-8 icon-cards-row">
                                    <div class="glide dashboard-numbers h-100">

                                        <ul class="list-inline row d-flex h-100">
                                            <li class="col h-100">
                                                <a href="#" class="card h-100 bg-primary">
                                                    <div class="card-body text-white d-flex flex-column justify-content-center align-items-center">
                                                        <i class="icon mb-3"><img src="di/ic-current.png" height="60"></i>
                                                        <p class="card-text mb-0">Current Consent</p>
                                                        <big class="lead text-center">500</big>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="col h-100">
                                                <a href="#" class="card h-100 bg-success">
                                                    <div class="card-body text-white d-flex flex-column justify-content-center align-items-center">
                                                        <i class="icon mb-3"><img src="di/ic-new-consent.png" height="60"></i>
                                                        <p class="card-text mb-0">New Consent</p>
                                                        <p class="lead text-center">+300</p>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="col h-100">
                                                <a href="#" class="card h-100 bg-gray">
                                                    <div class="card-body text-white d-flex flex-column justify-content-center align-items-center">
                                                        <i class="icon mb-3"><img src="di/ic-expire.png" height="60"></i>
                                                        <p class="card-text mb-0">Expire Consent</p>
                                                        <p class="lead text-center">-200</p>
                                                    </div>
                                                </a>
                                            </li>
                                            <li class="col h-100">
                                                <a href="#" class="card h-100 bg-danger">
                                                    <div class="card-body text-white d-flex flex-column justify-content-center align-items-center">
                                                        <i class="icon mb-3"><img src="di/ic-cancel.png" height="60"></i>
                                                        <p class="card-text mb-0">Cancel Consent</p>
                                                        <p class="lead text-center">-50</p>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card h-100">
                                        <ul class="list-inline m-0 list-total">
                                            <li class=" d-flex justify-content-between align-items-center flex-nowrap p-4">
                                                <p class="mr-2 mb-0">Total System</p>
                                                <big class="text-success">10</big>
                                            </li>
                                            <li class="bg-light d-flex justify-content-between align-items-center flex-nowrap p-4">
                                                <p class="mr-2 mb-0">Total Channel Input</p>
                                                <big class="text-success">5</big>
                                            </li>
                                            <li class=" d-flex justify-content-between align-items-center flex-nowrap p-4">
                                                <p class="mr-2 mb-0">Total Channel Input</p>
                                                <big class="text-success">250</big>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 mb-4">
                            <div class="card">
                                <div class="card-body">


                                    <div class="float-left float-none-xs">
                                        <div class="d-inline-block">
                                            <h5 class="d-inline">Consent Graph</h5>
                                            <!--<span class="text-muted text-small d-block">Unique Visitors</span>-->
                                        </div>
                                    </div>
                                    <div class="btn-group float-right float-none-xs mt-2">
                                        <button class="btn btn-outline-primary btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      This Week
                    </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">Last Week</a>
                                            <a class="dropdown-item" href="#">This Month</a>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <div class="dashboard-line-chart chart">
                                        <canvas id="salesChart"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </main>

  </div>
</template>
<script>

export default {
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {

  },
  created () {
    
  },
  mounted () {
    
            // let m = this.$modals.open({
            //     title: 'Title',
            //     theme: 'osx', // || mojave
            //     items: [{
            //             label: 'Username',
            //             name: 'username',
            //             type: 'text',
            //             value: 'Daniel',
            //         },
            //         {
            //             label: 'Bio',
            //             name: 'bio',
            //             type: 'textarea',
            //             value: 'web developer',
            //         }
            //     ],
            // });

            // m.onsave((m) => {
            //     m.loading();
            //     setTimeout(() => {
            //         console.log(m.formData())
            //         console.log(m.getItems())
            //         m.loaded()
            //     }, 500)
            // })

            // m.onreload(() => {
            //     console.log('Reloaded')
            // })

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
