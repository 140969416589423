//import { MemberService, ContentService } from '../resource'
import { UserService, ContentService } from '../service'
import VueJwtDecode from 'vue-jwt-decode'
const userModule = {
    namespaced: true,
    state: {
        token: null,
        privileges: [],
        isLoggedIn: false,
        member: null,
    },
    mutations: {
        SET_LOGIN_RESULT(state, result) {
            let tokenObject = VueJwtDecode.decode(result.access_token)
            console.log('tokenObject ======> ', tokenObject.privileges)

            state.token = result.access_token
            state.isLoggedIn = true
            state.privileges = tokenObject.privileges
                // state.member = result
        },
        // SET_TOKEN(state, result) {
        //     state.token = result
        // },
        SET_LOGOUT(state) {
            state.token = null
            state.member = null
            state.isLoggedIn = false
        },
    },
    actions: {
        login({ commit, state }, data) {
            return new Promise(async(resolve, reject) => {
                const result = await UserService.login(data)
                console.log(result)
                if (result != null && result.data && result.data.statuscode == 0) {

                    resolve(result.data)
                    commit('SET_LOGIN_RESULT', result.data)
                } else {
                    resolve(result.data)
                }
            })
        },
        hasPrivilege({ commit, state }, privilege) {
            return new Promise(async(resolve, reject) => {
                resolve(state.privileges.includes(privilege))
            })
        },
        // async loginMember({ commit, state }, data) {
        //     const result = await UserService.login(data)
        //     if (result != null && result.statuscode == 0) {
        //         // alert(data)
        //         commit('SET_LOGIN_RESULT', data)
        //     }
        // },
        async logout({ commit, state }) {
            commit('SET_LOGOUT')
        },
    },
    getters: {
        getToken(state) {
            return state.token
        },
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        getPrivileges(state) {
            return state.privileges
        },
    },
    // plugins: [vuexPersist.plugin]
}

export default userModule