import { ConsentService } from '../service'

const consentModule = {
    namespaced: true,
    state: {
        consentEdit: null,
    },
    mutations: {
        SET_CONSENTEDIT(state, consentEdit) {
            state.consentEdit = consentEdit
        }
    },
    actions: {
        async setConsentEdit({ commit, state }, data) {
            const consentEdit = data;
            commit('SET_CONSENTEDIT', consentEdit)
        },
        async listConsent({ commit, state }, data) {
            return new Promise(async(resolve, reject) => {
                const result = await ConsentService.listConsent(data)
                if (result != null && result.data && result.data.statuscode == 0) {
                    resolve(result.data)
                } else {
                    resolve(null)
                }
            })
        },
        async createConsent({ commit, state }, data) {
            return new Promise(async(resolve, reject) => {
                const result = await ConsentService.createConsent(data)
                if (result != null && result.data && result.data.statuscode == 0) {
                    resolve(result.data)
                } else {
                    resolve(null)
                }
            })
        },
        async modifyConsent({ commit, state }, data) {
            return new Promise(async(resolve, reject) => {
                const result = await ConsentService.modifyConsent(data)
                if (result != null && result.data && result.data.statuscode == 0) {
                    resolve(result.data)
                } else {
                    resolve(null)
                }
            })
        },
        async deleteConsent({ commit, state }, data) {
            return new Promise(async(resolve, reject) => {
                const result = await ConsentService.deleteConsent(data)
                if (result != null && result.data && result.data.statuscode == 0) {
                    resolve(result.data)
                } else {
                    resolve(null)
                }
            })
        },
        async viewConsent({ commit, state }, data) {
            return new Promise(async(resolve, reject) => {
                const result = await ConsentService.viewConsent(data)
                if (result != null && result.data && result.data.statuscode == 0) {
                    resolve(result.data)
                } else {
                    resolve(null)
                }
            })
        },
    },
    getters: {
        getConsentEdit(state) {
            return state.consentEdit
        }
    }
}

export default consentModule