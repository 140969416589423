<template >
  <div v-if="!isLoggedIn">
    <main>
        <div class="container">
            <div class="row h-100">
                <div class="col-12 col-md-10 mx-auto my-auto">
                    <div class="card auth-card">
                        <div class="position-relative image-side ">

                            <p class=" text-white h2">MAGIC IS IN THE DETAILS</p>

                            <p class="white mb-0">
                                Please use your credentials to login.
                                <br>If you are not a member, please
                                <a href="#" class="white">register</a>.
                            </p>
                        </div>
                        <div class="form-side">
                            <!-- <a href="Dashboard.Default.html">
                                <span class="logo-single"></span>
                            </a> -->
                            <h6 class="mb-4">Login</h6>
                            
                                <label class="form-group has-float-label mb-4">
                                    <input v-model="username" class="form-control" />
                                    <span>E-mail</span>
                                </label>

                                <label class="form-group has-float-label mb-4">
                                    <input v-model="password" class="form-control" type="password" placeholder="" />
                                    <span>Password</span>
                                </label>
                                <div class="d-flex justify-content-between align-items-center">
                                    <!-- <a href="#">Forget password?</a> -->
                                    <button v-on:click="doLogin()" class="btn btn-primary btn-lg btn-shadow" type="submit">LOGIN</button>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--         
        <div class="container-fluid">
            <div class="row">
               <input type="button" value="test" />
            </div>
            <div class="row">ssss
            </div>
        </div> -->

    </main>

  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
    ...mapGetters({
      menu: 'app/getMenu',
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
      login: 'user/login'
    }),
    
    doLogin: async function () {
        if(this.username == ''){
          this.showError('Warning', 'กรุณาระบุชื่อผู้ใช้')  
          return
        }
        if(this.password == ''){
          this.showError('Warning', 'กรุณาระบุรหัสผ่าน')  
          return
        }
        
        let loginResult = await this.login({ username:this.username, password : this.password})
        console.log(loginResult)
        if(loginResult.statuscode == 0){
          // this.$router.push('/')
          // this.$router.go('/')
          location.href='/'
        }else{
          this.showError('Error', 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')  
        }
        
    //  alert(this.username)
    },
    showError(title, message){
      this.$bvModal.msgBoxOk(message, {
        title: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      }).then(value => {
        
      })
      .catch(err => {
        // An error occurred
      })
    }
  },
  created () {
    
  },
  mounted () {
    

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
