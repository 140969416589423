<template>
  <div>

    <Header />

    <Menu />

    <router-view></router-view>

  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
export default {
  name: 'app',
  components: {
    Header,
    Menu,
    // Notification,
    // Header,
    // Home,
    // Footer,
    // CookieLaw
  },
  methods: {
    // onCookieAccept(){
    //   console.log("Cookie Accepted!!")
    // }
  },
  mounted() {
    //this.$swal("Good job!", "You clicked the button!", "success");
    //this.$swal('Hello word!')

    //this.$toast.open('You did it!You did it!You did it!You did it!');
  },
}
</script>

<style>
.Cookie--mytheme {
  background: #fff;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.busy-mask{
 z-index:99999;
}

</style>
