<template >
  <div>
    <main>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="mb-2">
					<h1>Customer</h1>
					<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
						<ol class="breadcrumb pt-0">
							<!--<li class="breadcrumb-item">
									<a href="#">Home</a>
                  </li>-->
							<li class="breadcrumb-item">
								<a href="consetn-list">Customer</a>
							</li>
							<li class="breadcrumb-item active text-gray" aria-current="page">View</li>
						</ol>
					</nav>
				</div>

				<div class="mb-2 d-flex justify-content-between align-items-center">
					<div class="col-l">
						<h2 class="sub-head font-weight-bold text-medium mb-0">Info</h2>
					</div>
				</div>

				<div class="card mb-4">
					<div class="card-body">
						<div class="row mb-0">
							<div class="col-sm-6">
								<p class="text-muted text-small mb-1">Customer Name:</p>
								<p>Sarawut P</p>
							</div>
							<div class="col-sm-6">
								<p class="text-muted text-small mb-1">ID Card:</p>
								<p>37609883876XX</p>
							</div>
						</div>

						<div class="row mb-0">
							<div class="col-sm-6">
								<p class="text-muted text-small mb-1">Phone:</p>
								<p>081-111-2222</p>
							</div>

							<div class="col-sm-6">
								<p class="text-muted text-small mb-1">Email:</p>
								<p>sarun.s@gmail.com</p>
							</div>
						</div>

						<div class="row mb-0">

							<div class="col-sm-6">
								<p class="text-muted text-small mb-1">Address :</p>
								<p>437/109 ลุมพินีคอนโด ถนนรัตนาธิเบศร์</p>
							</div>
						</div>
						<!-- 
                <div class="separator mb-3"></div>

                <div class="row mb-0">
                  <div class="col-sm-6 col-xl-4">
                    <p class="text-muted text-small mb-1">Address :</p>
                    <p>437/109 ลุมพินีคอนโด ถนนรัตนาธิเบศร์</p>
                  </div>
                </div> -->
					</div>
				</div>
				<!-- tab -->
				<div class="tab-zone">
					<div class="head-tab-title">
						<ul class="nav nav-tabs separator-tabs ml-0 mb-0" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" id="consent-tab" data-toggle="tab" href="#consent" role="tab" aria-controls="first" aria-selected="true">Consent List</a>
							</li>

							<li class="nav-item">
								<a class="nav-link" id="privacy-tab" data-toggle="tab" href="#privacy" role="tab" aria-controls="third" aria-selected="false">Consent Account History</a>
							</li>
						</ul>
					</div>
					<div class="tab-content">
						<!-- tab1 -->
						<div class="tab-pane show active" id="consent" role="tabpanel" aria-labelledby="consent-tab">
							<div class="mb-4 mt-n5 d-flex justify-content-end align-items-center">
								<div class="col-r pb-2 top-right-button-container d-flex align-items-end">
									<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#processAllConsent"  class="btn btn-primary btn-md top-right-button mr-1">Process Selected Consent</a>
									<a href="javascript:;" class="btn btn-primary btn-md top-right-button mr-1">Print Selected Consent</a>
								</div>
							</div>


							<div class="card main-consent-setting">
								<div class="card-body">
									<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
										<!--<table id="tablelist" class="data-table data-table-feature">-->
										<!--<table id="tablelist" class="data-table data-table-standard">-->
										<table class="data-table data-tables-pagination responsive nowrap tb_paging">
											<thead>
												<tr>
													<th><input type="checkbox" name="consent_id" /></th>
													<th>No.</th>
													<th>Channel</th>
													<th>Type</th>
													<th>Consent Title</th>
													<th>Start Date</th>
													<th>End Date</th>
													<th width="15%">Version</th>
													<!--th class="text-center pr-0">Status</th-->
													<th class="text-center sort-none" width="25%">Action</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><input type="checkbox" name="consent_id" /></td>
													<td>1</td>
													<td>Chatbot</td>
													<td class="text-center2">Public</td>
													<td>ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์</td>
													<td>01-Jan-2020</td>
													<td>31-Dec-2020</td>
													<td>1.0.7</td>
													<!--td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td-->
													<td class="text-center">
														<!-- <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerVersionHistoryPop" class="btn btn-primary btn-sm mr-2">Version History</a>
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Detail</a> -->
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#processConsent" class="btn btn-primary btn-md top-right-button mr-1">Process</a>
														<a href="javascript:;" class="btn btn-primary btn-sm mr-2">Print</a>
														
													</td>
												</tr>
												<tr>
													<td><input type="checkbox" name="consent_id" /></td>
													<td>2</td>
													<td>Web</td>
													<td class="text-center2">Private</td>
													<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์1	</td>
													<td>01-Jan-2020</td>
													<td>31-Dec-2020</td>
													<td>1.0.4</td>
													<!--td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td-->
													<td class="text-center">
														<!-- <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerVersionHistoryPop" class="btn btn-primary btn-sm mr-2">Version History</a>
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Detail</a> -->
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#processConsent" class="btn btn-primary btn-md top-right-button mr-1">Process</a>
														<a href="javascript:;" class="btn btn-primary btn-sm mr-2">Print</a>
														
													</td>
												</tr>
												<tr>
													<td><input type="checkbox" name="consent_id" /></td>
													<td>3</td>
													<td>Web</td>
													<td class="text-center2">Private</td>
													<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์2	</td>
													<td>01-Jan-2020</td>
													<td>31-Dec-2020</td>
													<td>1.0.4</td>
													<!--td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td-->
													<td class="text-center">
														<!-- <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerVersionHistoryPop" class="btn btn-primary btn-sm mr-2">Version History</a>
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Detail</a> -->
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#processConsent" class="btn btn-primary btn-md top-right-button mr-1">Process</a>
														<a href="javascript:;" class="btn btn-primary btn-sm mr-2">Print</a>
														
													</td>
												</tr>
												<tr>
													<td><input type="checkbox" name="consent_id" /></td>
													<td>4</td>
													<td>Web</td>
													<td class="text-center2">Private</td>
													<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์3	</td>
													<td>01-Jan-2020</td>
													<td>31-Dec-2020</td>
													<td>1.0.4</td>
													<!--td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td-->
													<td class="text-center">
														<!-- <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerVersionHistoryPop" class="btn btn-primary btn-sm mr-2">Version History</a>
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Detail</a> -->
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#processConsent" class="btn btn-primary btn-md top-right-button mr-1">Process</a>
														<a href="javascript:;" class="btn btn-primary btn-sm mr-2">Print</a>
														
													</td>
												</tr>
												<tr>
													<td><input type="checkbox" name="consent_id" /></td>
													<td>5</td>
													<td>Web</td>
													<td class="text-center2">Private</td>
													<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์4	</td>
													<td>01-Jan-2020</td>
													<td>31-Dec-2020</td>
													<td>1.0.4</td>
													<!--td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td-->
													<td class="text-center">
														<!-- <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerVersionHistoryPop" class="btn btn-primary btn-sm mr-2">Version History</a>
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Detail</a> -->
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#processConsent" class="btn btn-primary btn-md top-right-button mr-1">Process</a>
														<a href="javascript:;" class="btn btn-primary btn-sm mr-2">Print</a>
														
													</td>
												</tr>

											</tbody>
										</table>
									</div>
								</div>
							</div>

							

						</div>
						
						<!-- tab2 -->
						<div class="tab-pane fade" id="privacy" role="tabpanel" aria-labelledby="privacy-tab">
							<!-- <div class="mb-4 mt-n5 d-flex justify-content-end align-items-center">

								<div class="col-r pb-2 top-right-button-container d-flex align-items-end">
									<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#privacyAddPop" class="btn btn-primary btn-md top-right-button mr-1">+ Add</a>
								</div>
							</div> -->

							<br /><br /><h3>Consent History</h3><br />
							

							<div class="card main-consent-setting">
								<div class="card-body">
									<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
										<!--<table id="tablelist" class="data-table data-table-feature">-->
										<!--<table id="tablelist" class="data-table data-table-standard">-->
										<table class="data-table data-tables-pagination responsive nowrap tb_paging">
											<thead>
												<tr>
													<th>No.</th>
													<th>Date Time</th>
													<th>Channel</th>
													<th>Type</th>
													<th>Consent</th>
													<th width="15%">Version</th>

													<th>Consent Action</th>
													<!--th class="text-center pr-0">Status</th-->
													<th class="text-center sort-none" width="25%">Action</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>1</td>
													<td>31-Aug-2020 17:17:10</td>
													<td>Chatbot</td>
													<td class="text-center2">Public</td>
													<td>ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์</td>
													<td>1.0.7</td>
													<td>Accept</td>
													<!--td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td-->
													<td class="text-center">
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerVersionHistoryPop" class="btn btn-primary btn-sm mr-2">Version History</a>
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Detail</a>
														
													</td>
												</tr>
												<tr>
													<td>2</td>
													<td>11-Sep-2020 20:17:10</td>
													<td>Web</td>
													<td class="text-center2">Private</td>
													<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
													<td>1.0.4</td>

													<td>Reject</td>
													<!--td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td-->
													<td class="text-center">
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerVersionHistoryPop" class="btn btn-primary btn-sm mr-2">Version History</a>
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerEditPop" class="btn btn-primary btn-sm mr-2">Detail</a>
														
													</td>
												</tr>

											</tbody>
										</table>
									</div>
								</div>
							</div>

							<br /><br /><h3>Privacy Notice</h3><br />

							<div class="card main-consent-setting">
								<div class="card-body">
									<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
										<!--<table id="tablelist" class="data-table data-table-feature">-->
										<!--<table id="tablelist" class="data-table data-table-standard">-->
										<table class="data-table data-tables-pagination responsive nowrap tb_paging">
											<thead>
												<tr>
													<th>No.</th>
													<th>Date Time</th>
													<th>Channel</th>
													<th>Type</th>
													<th>Privacy title</th>
													<th width="15%">Version</th>
													<!--th class="text-center pr-0">Status</th-->
													<th class="text-center sort-none" width="15%">Action</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>1</td>
													<td>31-Aug-2020 17:17:10</td>
													<td>Chatbot</td>
													<td class="text-center2">Public</td>
													<td>แจ้งเตือนขอเก็บข้อมูลทั่วไป</td>
													<td>1.0.3</td>
													<!--td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td-->
													<td class="text-center">
														<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#customerPrivacyHistoryPop" class="btn btn-primary btn-sm">Version History</a>
													</td>
												</tr>

											</tbody>
										</table>
									</div>
								</div>
							</div>


						</div>
					</div>
				</div>

				<!-- /tab -->





			</div>
		</div>
	</div>

	<!-- table history modal -->
	<div class="modal fade show" id="customerPrivacyHistoryPop">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Consent Account History</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
				
				</div>
				<form>
					<div class="modal-body">
						<div class="row">
							<table class="data-table data-tables-pagination responsive nowrap tb_paging">
								<thead>
									<tr>
										
										<th width="15%">Version</th>
										<th>Date Time</th>
										<th>Channel</th>
										<th>Type</th>
										<th>Privacy</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										
										<td>1.0.1</td>
										<td>31-Aug-2020 17:17:10</td>
										<td>Chatbot</td>
										<td class="text-center2">Private</td>
										<td>แจ้งเตือนขอเก็บข้อมูลทั่วไป	</td>
										<td>Accept</td>
									</tr>
									<tr>
										<td>1.0.2</td>
										<td>11-Sep-2020 20:17:10</td>
										<td>Web</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
										<td>Reject</td>
									</tr>
									<tr>
										<td>1.0.3</td>
										<td>12-Sep-2020 20:17:10</td>
										<td>Web</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
										<td>Accept</td>
									</tr>

								</tbody>
							</table>
				
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- /table history modal -->

	<!-- table history modal -->
	<div class="modal fade show" id="customerVersionHistoryPop">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Consent Account History</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
				
				</div>
				<form>
					<div class="modal-body">
						<div class="row">
							<table class="data-table data-tables-pagination responsive nowrap tb_paging">
								<thead>
									<tr>
										
										<th width="15%">Version</th>
										<th>Date Time</th>
										<th>Channel</th>
										<th>Type</th>
										<th>Consent</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										
										<td>1.0.1</td>
										<td>31-Aug-2020 17:17:10</td>
										<td>Chatbot</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์</td>
										<td>Accept</td>
									</tr>
									<tr>
										<td>1.0.2</td>
										<td>11-Sep-2020 20:17:10</td>
										<td>Web</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
										<td>Reject</td>
									</tr>
									<tr>
										<td>1.0.3</td>
										<td>12-Sep-2020 20:17:10</td>
										<td>Web</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
										<td>Accept</td>
									</tr>

									<tr>
										<td>1.0.4</td>
										<td>14-Sep-2020 20:17:10</td>
										<td>Web</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
										<td>Accept</td>
									</tr>

									<tr>
										<td>1.0.5</td>
										<td>15-Sep-2020 20:17:10</td>
										<td>Web</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
										<td>Accept</td>
									</tr>

									<tr>
										<td>1.0.6</td>
										<td>20-Sep-2020 20:17:10</td>
										<td>Web</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
										<td>Accept</td>
									</tr>

									<tr>
										<td>1.0.7</td>
										<td>25-Sep-2020 20:17:10</td>
										<td>Web</td>
										<td class="text-center2">Private</td>
										<td>ขอเก็บข้อมูลลูกค้าสินเชื่อประกันรถยนต์	</td>
										<td>Accept</td>
									</tr>
								</tbody>
							</table>
				
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- /table history modal -->

	<!-- add modal -->
	<div class="modal fade show" id="processAllConsent">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Consent Action</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
				
				</div>
				<form>
					<div class="modal-body">
						<div class="row">

							<div class="col-sm-12 form-group">
								<label>Consents :</label> ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์<br />
								<label>Channel :</label> Chatbot<br />
								<label>Consent Type: </label> Private<br />
								<label>Consent Start Date: </label> 01-Jan-2020<br />
								<label>Consent End Date: </label> 31-Dec-2020<br />
								<label>Consent Short Description (TH): </label> ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุคคลของข้าพเจ้า<br />
								<label>Consent Description (TH): </label> ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของข้าพเจ้า เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า<br />
								<ul class="row list-inline">
									<li class="col-6">
										<div class="custom-control custom-radio d-inline-block mr-4">
											<input type="radio" id="a-accept11" name="a22" class="custom-control-input">
											<label class="custom-control-label" for="a-accept11">ยอมรับ</label>
										</div>
										<div class="custom-control custom-radio d-inline-block">
											<input type="radio" id="a-no22" name="a22" class="custom-control-input">
											<label class="custom-control-label" for="a-no22">ไม่ยอมรับ</label>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<hr />
						<div class="row">

							<div class="col-sm-12 form-group">
								<label>Consents :</label> ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์<br />
								<label>Channel :</label> Chatbot<br />
								<label>Consent Type: </label> Private<br />
								<label>Consent Start Date: </label> 01-Jan-2020<br />
								<label>Consent End Date: </label> 31-Dec-2020<br />
								<label>Consent Short Description (TH): </label> ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุคคลของข้าพเจ้า<br />
								<label>Consent Description (TH): </label> ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของข้าพเจ้า เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า<br />
								<ul class="row list-inline">
									<li class="col-6">
										<div class="custom-control custom-radio d-inline-block mr-4">
											<input type="radio" id="a-accept33" name="a33" class="custom-control-input">
											<label class="custom-control-label" for="a-accept33">ยอมรับ</label>
										</div>
										<div class="custom-control custom-radio d-inline-block">
											<input type="radio" id="a-no33" name="a33" class="custom-control-input">
											<label class="custom-control-label" for="a-no33">ไม่ยอมรับ</label>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<hr />
						<div class="row">

							<div class="col-sm-12 form-group">
								<label>Consents :</label> ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์<br />
								<label>Channel :</label> Chatbot<br />
								<label>Consent Type: </label> Private<br />
								<label>Consent Start Date: </label> 01-Jan-2020<br />
								<label>Consent End Date: </label> 31-Dec-2020<br />
								<label>Consent Short Description (TH): </label> ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุคคลของข้าพเจ้า<br />
								<label>Consent Description (TH): </label> ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของข้าพเจ้า เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า<br />
								<ul class="row list-inline">
									<li class="col-6">
										<div class="custom-control custom-radio d-inline-block mr-4">
											<input type="radio" id="a-accept44" name="a44" class="custom-control-input">
											<label class="custom-control-label" for="a-accept44">ยอมรับ</label>
										</div>
										<div class="custom-control custom-radio d-inline-block">
											<input type="radio" id="a-no44" name="a44" class="custom-control-input">
											<label class="custom-control-label" for="a-no44">ไม่ยอมรับ</label>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary">Submit</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- /add modal -->

	<!-- add modal -->
	<div class="modal fade show" id="processConsent">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Consent Action</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
				
				</div>
				<form>
					<div class="modal-body">
					<div class="row">

						<div class="col-sm-12 form-group">
							<label>Consents :</label> ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์<br />
							<label>Channel :</label> Chatbot<br />
							<label>Consent Type: </label> Private<br />
							<label>Consent Start Date: </label> 01-Jan-2020<br />
							<label>Consent End Date: </label> 31-Dec-2020<br />
							<label>Consent Short Description (TH): </label> ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุคคลของข้าพเจ้า<br />
							<label>Consent Description (TH): </label> ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของข้าพเจ้า เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า<br />
							<ul class="row list-inline">
								<li class="col-6">
									<div class="custom-control custom-radio d-inline-block mr-4">
										<input type="radio" id="a-accept2" name="check" class="custom-control-input">
										<label class="custom-control-label" for="a-accept2">ยอมรับ</label>
									</div>
									<div class="custom-control custom-radio d-inline-block">
										<input type="radio" id="a-no2" name="check" class="custom-control-input">
										<label class="custom-control-label" for="a-no2">ไม่ยอมรับ</label>
									</div>
								</li>
							</ul>
						</div>
					</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary">Submit</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- /add modal -->

	<!-- add modal -->
	<div class="modal fade show" id="customerAddPop">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Add New</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
				
				</div>
				<form>
					<div class="modal-body">
					<div class="row">
						<div class="col-sm-12 form-group cv-select-cr">
							<label>Consent :</label>
							<select class="form-control select2-single" data-width="100%">
								<option label="&nbsp;">Please select	</option>
								<option value="Option 1">ขอเก็บข้อมูลลูกค้าสินเชื่อรถจักรยานยนต์</option>
								<option value="Option 2">ขอเก็บข้อมูลลูกค้าสินเชื่อเงินสด	</option>
							</select>
						</div>

						<div class="col-sm-12 form-group">
							<label>Consents :</label>
							<ul class="row list-inline">
							<li class="col-6"><span>consent 1 </span> <br>
								<div class="custom-control custom-radio d-inline-block mr-4">
                                    <input type="radio" id="a-accept" name="a" class="custom-control-input">
                                    <label class="custom-control-label" for="a-accept">Accept</label>
                                </div>
								<div class="custom-control custom-radio d-inline-block">
                                    <input type="radio" id="a-no" name="a" class="custom-control-input">
                                    <label class="custom-control-label" for="a-no">No</label>
                                </div>
							</li>
							<li class="col-6"><span>consent 2 </span> <br>
								<div class="custom-control custom-radio d-inline-block mr-4">
                                    <input type="radio" id="b-accept" name="b" class="custom-control-input">
                                    <label class="custom-control-label" for="b-accept">Accept</label>
                                </div>
								<div class="custom-control custom-radio d-inline-block">
                                    <input type="radio" id="b-no" name="b" class="custom-control-input">
                                    <label class="custom-control-label" for="b-no">No</label>
                                </div>
							</li>
							<li class="col-6"><span>consent 3 </span> <br>
								<div class="custom-control custom-radio d-inline-block mr-4">
                                    <input type="radio" id="c-accept" name="c" class="custom-control-input">
                                    <label class="custom-control-label" for="c-accept">Accept</label>
                                </div>
								<div class="custom-control custom-radio d-inline-block">
                                    <input type="radio" id="c-no" name="c" class="custom-control-input">
                                    <label class="custom-control-label" for="c-no">No</label>
                                </div>
							</li>

							<li class="col-6"><span>consent 4 </span> <br>
								<div class="custom-control custom-radio d-inline-block mr-4">
                                    <input type="radio" id="d-accept" name="d" class="custom-control-input">
                                    <label class="custom-control-label" for="d-accept">Accept</label>
                                </div>
								<div class="custom-control custom-radio d-inline-block">
                                    <input type="radio" id="d-no" name="d" class="custom-control-input">
                                    <label class="custom-control-label" for="d-no">No</label>
                                </div>
							</li>
							<li class="col-6"><span>consent 5 </span> <br>
								<div class="custom-control custom-radio d-inline-block mr-4">
                                    <input type="radio" id="e-accept" name="e" class="custom-control-input">
                                    <label class="custom-control-label" for="e-accept">Accept</label>
                                </div>
								<div class="custom-control custom-radio d-inline-block">
                                    <input type="radio" id="e-no" name="e" class="custom-control-input">
                                    <label class="custom-control-label" for="e-no">No</label>
                                </div>
							</li>
							<li class="col-6"><span>consent 6 </span> <br>
								<div class="custom-control custom-radio d-inline-block mr-4">
                                    <input type="radio" id="f-accept" name="f" class="custom-control-input">
                                    <label class="custom-control-label" for="f-accept">Accept</label>
                                </div>
								<div class="custom-control custom-radio d-inline-block">
                                    <input type="radio" id="f-no" name="f" class="custom-control-input">
                                    <label class="custom-control-label" for="f-no">No</label>
                                </div>
							</li>
							</ul>
						</div>

						<div class="col-sm-6 form-group">
							<label>Method :</label>
							<input type="text" class="form-control" value="">
						</div>

						<div class="col-sm-6 form-group">
							<label>Region :</label>
							<input type="text" class="form-control" value="">
						</div>

						<div class="col-sm-6 form-group">
							<label>Area :</label>
							<input type="text" class="form-control" value="">
						</div>

						<div class="col-sm-6 form-group">
							<label>Branch :</label>
							<input type="text" class="form-control" value="">
						</div>

						<div class="col-sm-6 form-group">
							<label>REF no :</label>
							<input type="text" class="form-control" value="">
						</div>

						<div class="col-sm-6 form-group">
							<label>Contact no :</label>
							<input type="text" class="form-control" value="">
						</div>

						<div class="col-sm-6 form-group">
							<label>Remark :</label>
							<textarea class="form-control" rows="10" name="jQueryDetail" required></textarea>
						</div>

						<div class="col-sm-6 form-group">
							<label>Ticket no :</label>
							<input type="text" class="form-control" value="">
						</div>
					</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary">Submit</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- /add modal -->

	<!-- Edit modal -->
	<div class="modal fade show" id="customerEditPop">

		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">View</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
				
				</div>
				<form>
					<div class="modal-body">
					<div class="row">
						<div class="col-sm-12 form-group cv-select-cr">
							<label>Consent :</label>
							<select disabled class="form-control select2-single" data-width="100%">
								<option value="Option 1" selected>ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์	</option>
								<option value="Option 2">Web</option>
							</select>
						</div>

						<div class="col-sm-12 form-group">
							<label>Consents :</label><br/>
							<ul class="row list-inline">
							<li class="col-4"> <button type="button" class="btn btn-danger btn-sm btn-xs ml-2">revoke</button> </li>
							</ul>
						</div>

						<div class="col-sm-6 form-group">
							<label>Method :</label>
							<input type="text" class="form-control" value="method data">
						</div>

						<div class="col-sm-6 form-group">
							<label>Region :</label>
							<input type="text" class="form-control" value="1234">
						</div>

						<div class="col-sm-6 form-group">
							<label>Area :</label>
							<input type="text" class="form-control" value="45562">
						</div>

						<div class="col-sm-6 form-group">
							<label>Branch :</label>
							<input type="text" class="form-control" value="brch234">
						</div>

						<div class="col-sm-6 form-group">
							<label>REF no :</label>
							<input type="text" class="form-control" value="ref_12736d">
						</div>

						<div class="col-sm-6 form-group">
							<label>Contact no :</label>
							<input type="text" class="form-control" value="1293845">
						</div>

						<div class="col-sm-6 form-group">
							<label>Remark :</label>
							<textarea class="form-control" rows="10" name="jQueryDetail" required>Test remark</textarea>
						</div>

						<div class="col-sm-6 form-group">
							<label>Ticket no :</label>
							<input type="text" class="form-control" value="1293845">
						</div>
					</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary">Submit</button>
					</div>
				</form>
			</div>
		</div>

	</div>
	<!-- /Edit modal -->

	<!-- add modal -->
	<div class="modal fade show" id="privacyAddPop">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Add New</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
				
				</div>
				<form>
					<div class="modal-body">
						<div class="form-group cv-select-cr">
							<label>Channel :</label>
							<select class="form-control select2-single" data-width="100%">
								<option label="&nbsp;">All Channal</option>
								<option value="Option 1">Chatbot</option>
								<option value="Option 2">Web</option>
							</select>
						</div>

						<div class="form-group">
							<label>Consents :</label><br/>
							<input type="radio" name="a"/> Accept <input type="radio" name="a"> No </span> <br/>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary">Submit</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- /add modal -->
</main>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
    };
  },
  components: {},
  async beforeCreate() {},
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {
	  $('.tb_paging').DataTable( {
		searching: false,
		bLengthChange: true,
		destroy: true,
		info: true,
		"pagingType": "simple_numbers",
		//scrollCollapse: !0,
		//scrollX: true
	} );
  },
  destroyed() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
