<template >
    <div class="example-modal-content">
        <div id="customerAddPop">
            <div role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add New Role</h5>
                        <button type="button" class="close" @click="closePopUp" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form>
                    <div class="modal-body">
                    <div class="row">     
                        <!-- <div class="col-12 form-group cv-select-cr">
                            <label>Version :</label>
                            <input type="text" class="form-control" value="">
                        </div> -->

                        <div class="col-sm-12 form-group">
                            <label>Role Name :</label>
                            <input type="text" class="form-control" name="jQueryDetail" required="" />
                        </div>

                        <div class="col-sm-12 form-group">
                            <label>Role Title (TH) :</label>
                            <input type="text" class="form-control" name="jQueryDetail" required="" />
                            </div>
                            
                        <div class="col-sm-12 form-group">
                            <label>Role Title (EN) :</label>
                            <input type="text" class="form-control" name="jQueryDetail" required="" />
                        </div>

                        <div class="col-sm-12 form-group">
                            <label>Privilege :</label><br />
                           
                            <label>- Report:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserAdd" />
                        <label class="custom-control-label" for="UserAdd">Dashboard</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserEdit" />
                        <label class="custom-control-label" for="UserEdit">Report 1</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserDel" />
                        <label class="custom-control-label" for="UserDel">Report 2</label>
                      </div>

                      <label>- User Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserAdd" />
                        <label class="custom-control-label" for="UserAdd">Add User</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserEdit" />
                        <label class="custom-control-label" for="UserEdit">Edit User</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserDel" />
                        <label class="custom-control-label" for="UserDel">Delete User</label>
                      </div>

                      <label>- Role Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Role</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Role</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Role</label>
                      </div>

                      <label>- Consent Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Consent</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Consent</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Consent</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Publish Consent</label>
                      </div>

                      <label>- Consent Group Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Consent Group</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Consent Group</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Consent Group</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Publish Consent Group</label>
                      </div>

                      <label>- Privacy Notice Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Privacy Notice</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Privacy Notice</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Privacy Notice</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Publish Privacy Notice</label>
                      </div>

                      <label>- Customer Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Customer</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Customer</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Customer</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Publish Customer</label>
                      </div>
                        </div>

                        
                    </div>    
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="closePopUp" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary">Submit</button>
                    </div>
                    </form>
                </div>
            </div>
	    </div>
    </div>
</template>
<script>
const name = `dynamic-modal-${Math.random()}`
export default {
  props: ['name'],
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {
      
    closePopUp() {
       // alert(name)
       this.$emit('close')
          //this.$modal.show(AddRole, {})
       // this.$modal.hide(name)
        //console.log(this.$modal);
    },
  },
  created () {
    
  },
  mounted () {
    


  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.example-modal-content {

  z-index: 1050 !important;
}
</style>
