<template >
  <div>
    <main>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>Privacy notice</h1>
						<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0">
								<!--<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>
								<li class="breadcrumb-item">
									<a href="#">Library</a>
								</li>-->
								<li class="breadcrumb-item active text-gray" aria-current="page">Privacy notice</li>
							</ol>
						</nav>
						
                    </div>

                    <!-- <div class="mb-2 d-flex justify-content-between">
						<div class="col-l">
							<a class="btn pt-0 pl-0 d-inline-block d-md-none" data-toggle="collapse" href="#displayOptions"
								role="button" aria-expanded="true" aria-controls="displayOptions">
								Display Options
								<i class="simple-icon-arrow-down align-middle"></i>
							</a>
							<div class="collapse d-md-block" id="displayOptions">
								<div class="d-flex align-items-end">

									<div class="float-md-left mr-2 mb-1 dropdown-as-select">
										<label class="d-block">Type :</label>
										<button class="btn bg-white btn-xs dropdown-toggle" type="button"
											data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											All 
										</button>
										<div class="dropdown-menu">
											<a class="dropdown-item active" href="#">Private</a>
											<a class="dropdown-item" href="#">Public</a>
										</div>
									</div>
									
									<div class="input-daterange no-gutters d-flex flex-nowrap align-items-end mr-2 mb-1" id="datepicker-report"> 
										<div class="col mb-0">
											<label class="d-block">Start date :</label>
											<input type="text" class="form-control form-control-sm bg-white rounded-2" name="Select date"
														placeholder="Start" value="05/04/2020" />
										</div>
										<span class="form-group pl-2 pr-2 mb-0"><label>to</label></span>
										<div class="col mb-0">
											<label class="d-block">End date :</label>
											<input type="text" class="form-control form-control-sm bg-white rounded-2" name="Select date"
														placeholder="End" value="05/15/2020" />
										</div>
									</div>

	
									<div class="float-md-left mr-1 mb-1">
										
										<button class="btn btn-primary btn-xs text-white" type="button">
											Search
										</button>
										
									</div>

								</div>
							</div>
						</div>
						<div class="col-r pb-2 top-right-button-container d-flex align-items-end">
              				<a href="/privacy-add" class="btn btn-primary btn-md top-right-button  mr-1">+ Add Privacy notice</a>
						</div>
					</div>

                    <div class="separator mb-5"></div> -->


					<div class="srh-bar mb-4 d-flex justify-content-between flex-row flex-nowrap">
						<div class="col p-0 pl-1 pr-0">
							<a class="btn p-2 d-inline-block d-md-none" data-toggle="collapse" href="#searchOptions" role="button" aria-expanded="true" aria-controls="searchOptions">
								Display Options
								<i class="simple-icon-arrow-down align-middle"></i>
							</a>
							<div class="card collapse d-md-block" id="searchOptions">
								<div class="card-body p-3 d-flex flex-wrap justify-content-between h-100 align-items-end">
									<div class="d-flex flex-wrap w-100 mb-3">
									
										
										<div class="col-12 col-sm form-group mb-2">
											<label>Privacy Title</label>
											<input type="text" class="form-control" />

										</div>

										<div class="col-12 col-sm form-group mb-2">
											<label>Privacy Type</label>
											<select class="form-control select2-single" data-width="100%" data-placeholder="Select Consent Status" data-minimum-results-for-search="Infinity">
												<option>All Type</option>
												<option>Public</option>
												<option>Private</option>
											</select>

										</div>
										
										
									</div>

									<div class="d-flex flex-wrap w-100 align-items-end">

										<div class="col-12 col-sm form-group mb-2">
											<label>Period</label>
											<div class="row mb-0 align-items-center">
												<div class="col pr-2">
													<div class="input-group">
															<span class="input-group-text input-group-append input-group-addon border-right-0">
																<i class="simple-icon-calendar"></i>
															</span>
															<input type="text" class="input-sm form-control border-left-0" name="start" placeholder="Start date">
														</div>

												</div>
												<div class="font-weight-bold">To</div>
												<div class="col pl-2">
													<div class="input-group">
															<span class="input-group-text input-group-append input-group-addon border-right-0">
																<i class="simple-icon-calendar"></i>
															</span>
															<input type="text" class="input-sm form-control border-left-0" name="end" placeholder="End date">
														</div>
												</div>
											</div>
										</div>
										
										<div class="col-12 col-sm form-group mb-2">
											<label>Status</label>
											<select class="form-control select2-single" data-width="100%" data-placeholder="Select Consent Status" data-minimum-results-for-search="Infinity">
												<option>All Status</option>
												<option>Draft</option>
												<option>Active</option>
												<option>In-Active</option>
												<option>Expired</option>
											</select>

										</div>


										<div class="top-right-button-container text-nowrap col-12 col-sm-auto mb-2">

											<button class="btn btn-primary btn-md top-right-button rounded-05" type="button" id="btnSearch" style="min-width: 120px"> <!--<i class="icon-img"><img src="di/ic-search-wh.png" height="20"></i>--> Search</button>
										</div>
										
									</div>

								</div>
							</div>
						</div>

					</div>
					
					<div class="headbar-tb mb-4 d-flex justify-content-end align-items-center">
						<div class="col-r top-right-button-container d-flex align-items-end">
							<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#consentAddPop" class="btn btn-primary btn-md top-right-button  mr-1">+ Add Privacy</a>
							<!-- <a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#consentAddPop" class="btn btn-primary btn-md top-right-button mr-1">+ Add Consent</a> -->
						</div>
					</div>

                    <div class="separator mb-5"></div>

					
					<div class="card main-consent-setting">
					<div class="card-body">
						<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
							<!--<table id="tablelist" class="data-table data-table-feature">-->
							<!--<table id="tablelist" class="data-table data-table-standard">-->
                           <!-- <table class="data-table dataTable no-footer responsive nowrap"> -->
							   <table id="tb_paging" class="responsive nowrap">
							
									<thead>
										<tr>
											<th>No.</th>
											<th>Type</th>
											<th>Title</th>
											<th>Start Date</th>
											<th>End Date</th>
											<th>Current Version</th>
											<th>Last Update</th>
											<th class="text-center sort-none">Status</th>
											<th class="text-center sort-none" width="20%">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>Private</td>
											<td>แจ้งเตือนขอเก็บข้อมูลทั่วไป</td>
											<td>01-Jan-2020</td>
											<td>31-Dec-2020</td>
											<td>1.0.3</td>
											<td>31-Aug-2020 17:17:10</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
												<a href="/privacy-detail" class="btn btn-primary mr-2 btn-sm">Detail</a>
												<a href="javascript:;" data-toggle="modal" data-backdrop="static" data-target="#consentEditPop" class="btn btn-primary mr-2 btn-sm">Edit</a>
												<a href="#" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>

                    					<!-- <tr>
											<td>2</td>
											<td>Private</td>
											<td>
												<a class="text-dark" href="#">
													NTL loan system management													
												</a>
											</td>
											<td>01-Jan-2020 to 31-Dec-2020</td>
											<td>1.0.0-b01</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
												<a href="/privacy-detail" class="btn btn-primary mr-2 btn-sm">View</a>
												<a href="/privacy-edit" class="btn btn-primary mr-2 btn-sm">Edit</a>
												<a href="#" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>

										<tr>
											<td>3</td>
											<td>Private</td>
											<td>
												<a class="text-dark" href="#">
													NTL loan system management													
												</a>
											</td>
											<td>01-Jan-2020 to 31-Dec-2020</td>
											<td>1.0.0-b01</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
												<a href="/privacy-detail" class="btn btn-primary mr-2 btn-sm">View</a>
												<a href="/privacy-edit" class="btn btn-primary mr-2 btn-sm">Edit</a>
												<a href="#" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>

										<tr>
											<td>4</td>
											<td>Private</td>
											<td>
												<a class="text-dark" href="#">
													NTL loan system management													
												</a>
											</td>
											<td>01-Jan-2020 to 31-Dec-2020</td>
											<td>1.0.0-b01</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
												<a href="/privacy-detail" class="btn btn-primary mr-2 btn-sm">View</a>
												<a href="/privacy-edit" class="btn btn-primary mr-2 btn-sm">Edit</a>
												<a href="#" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>

										<tr>
											<td>5</td>
											<td>Private</td>
											<td>
												<a class="text-dark" href="#">
													NTL loan system management													
												</a>
											</td>
											<td>01-Jan-2020 to 31-Dec-2020</td>
											<td>1.0.0-b01</td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
												<a href="/privacy-detail" class="btn btn-primary mr-2 btn-sm">View</a>
												<a href="/privacy-edit" class="btn btn-primary mr-2 btn-sm">Edit</a>
												<a href="#" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr> -->
									</tbody>
								</table>
								
		

						</div>
						
						<!-- <nav class="mt-4 mb-3">
                        <ul class="pagination justify-content-center mb-0">
                            <li class="page-item ">
                                <a class="page-link first" href="#">
                                    <i class="simple-icon-control-start"></i>
                                </a>
                            </li>
                            <li class="page-item ">
                                <a class="page-link prev" href="#">
                                    <i class="simple-icon-arrow-left"></i>
                                </a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item ">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item ">
                                <a class="page-link next" href="#" aria-label="Next">
                                    <i class="simple-icon-arrow-right"></i>
                                </a>
                            </li>
                            <li class="page-item ">
                                <a class="page-link last" href="#">
                                    <i class="simple-icon-control-end"></i>
                                </a>
                            </li>
                        </ul>
                    </nav> -->
					</div>
					</div>
                </div>
            </div>
        </div>

		<div class="modal fade show" id="consentAddPop">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add New Privacy Notice</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">
                <div class="row">     
                      <!-- <div class="col-12 form-group cv-select-cr">
                        <label>Version :</label>
                        <input type="text" class="form-control" value="">
                      </div> -->

					<div class="col-sm-6 form-group">
						<label>Privacy Notice Title (TH) :</label>
						<input type="text" class="form-control" name="jQueryDetail" required="" />
						</div>
						
						<div class="col-sm-6 form-group">
							<label>Privacy Notice Title (EN) :</label>
							<input type="text" class="form-control" name="jQueryDetail" required="" />
						</div>

                      <div class="col-sm-6 form-group">
                        <label>Detail (TH) :</label>
                        <textarea class="form-control" rows="7" name="jQueryDetail" required=""></textarea>
                      </div>
                      
                      <div class="col-sm-6 form-group">
                          <label>Detail (EN) :</label>
                          <textarea class="form-control" rows="7" name="jQueryDetail" required=""></textarea>
                        </div>

						<div class="col-sm-6 form-group">
						<label>Consent Start Date :</label> 
						<input type="text" class="form-control datepicker" name="jQueryDetail" required="" />
						</div>
						
						<div class="col-sm-6 form-group">
							<label>Consent End Date :</label>
							<input type="text" class="form-control datepicker" name="jQueryDetail" required="" />
						</div>
                </div>    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary">Submit</button>
                </div>
                </form>
            </div>
        </div>
    </div>

	<div class="modal fade show" id="consentEditPop">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Privacy Notice</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                <div class="modal-body">
                <div class="row">     
                      <!-- <div class="col-12 form-group cv-select-cr">
                        <label>Version :</label>
                        <input type="text" class="form-control" value="1.0.0">
                      </div> -->

					<div class="col-sm-6 form-group">
						<label>Privacy Notice Title (TH) :</label>
						<input type="text" class="form-control" name="jQueryDetail" value="ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์	" />
						</div>
						
						<div class="col-sm-6 form-group">
							<label>Privacy Notice Title (EN) :</label>
							<input type="text" class="form-control" name="jQueryDetail" value="ขอเก็บข้อมูลลูกค้าสินเชื่อรถยนต์	" />
						</div>

                      <div class="col-sm-6 form-group">
                        <label>Detail (TH) :</label>
                        <textarea class="form-control" rows="7" name="jQueryDetail" required="">ตกลงยินยอมให้ บมจ.xxxx เปิดผข้อมูลส่วนบุกกของช้พจ เช่น ชื่อ ช่องทางในการติดต่อเพศอายุการศึกษา เป็นต้น แต่ทั้งนี้ ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้พเจ้าเช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชีเป็นต้น ให้แก่กลุ่มธุรกิจทางการเงินของธนาคารและให้กลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้มูลดังกล่ว พื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการพิจารณานำเสนอผลิตภัณฑ์ หรือเพื่อส่งเสริมการขายผลิตภัณฑ์บริกร และข้อสนอพิเศษอื่นๆ ของบริษัทในกลุ่มธุรกิจการเงินของธนาคาร ให้แก่ข้าพเจ้า</textarea>
                      </div>
                      
                      <div class="col-sm-6 form-group">
                          <label>Detail (EN) :</label>
                          <textarea class="form-control" rows="7" name="jQueryDetail" required="">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</textarea>
                        </div>

						<div class="col-sm-6 form-group">
						<label>Consent Start Date :</label> 
						<input type="text" class="form-control datepicker" name="jQueryDetail" value="01-Jan-2020" />
						</div>
						
						<div class="col-sm-6 form-group">
							<label>Consent End Date :</label>
							<input type="text" class="form-control datepicker" name="jQueryDetail" value="31-Dec-2020" />
						</div>

						<div class="col-12 form-group cv-select-cr">
                        <label>Consent Status :</label>
                        <select class="form-control select2-single" data-width="100%">
											<option value="public" selected>Active</option>
											<option value="public">In-Active</option>
										</select>
                      </div>
                </div>    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary">Submit</button>
                </div>
                </form>
            </div>
        </div>
    </div>


    </main>

  </div>
</template>
<script>

export default {
  name: 'Login',
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {

  },
  created () {
    
  },
  mounted () {
	$('#tb_paging').DataTable( {
		searching: false,
		bLengthChange: true,
		destroy: true,
		info: true,
		"pagingType": "simple_numbers",
		//scrollCollapse: !0,
		//scrollX: true
	} );

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
