import HttpRequest from './http_request'
import store from '../store'
class ConsentService extends HttpRequest {
    createUser(data) {
        return this.create('', data)
    }
    async listConsent(data) {
        let token = store.getters['user/getToken'];
        console.log('token :: ', token, data)
        try {
            this.setHeader({
                key: 'Authorization',
                value: 'Bearer ' + token
            })
            return this.post('/consent/search', data)
        } catch (e) {
            // console.error(e)
            return null
        }
    }
    async createConsent(data) {
        let token = store.getters['user/getToken'];
        console.log('token :: ', token, data)
        try {
            this.setHeader({
                key: 'Authorization',
                value: 'Bearer ' + token
            })
            return this.post('/consent/create', data)
        } catch (e) {
            // console.error(e)
            return null
        }
    }
    async modifyConsent(data) {
        let token = store.getters['user/getToken'];
        console.log('token :: ', token, data)
        try {
            this.setHeader({
                key: 'Authorization',
                value: 'Bearer ' + token
            })
            return this.post('/consent/modify', data)
        } catch (e) {
            // console.error(e)
            return null
        }
    }
    async viewConsent(data) {
        let token = store.getters['user/getToken'];
        console.log('token :: ', token, data)
        try {
            this.setHeader({
                key: 'Authorization',
                value: 'Bearer ' + token
            })
            return this.post('/consent/view', data)
        } catch (e) {
            // console.error(e)
            return null
        }
    }
    async deleteConsent(data) {
        let token = store.getters['user/getToken'];
        console.log('token :: ', token, data)
        try {
            this.setHeader({
                key: 'Authorization',
                value: 'Bearer ' + token
            })
            return this.post('/consent/delete', data)
        } catch (e) {
            // console.error(e)
            return null
        }
    }
}

export default ConsentService