<template>
  <div>
    <b-button v-b-modal.modal-scrollable>Launch scrolling modal</b-button>
    <b-modal
      id="modal-consent-add"
      scrollable
      title="Add Consent"
      size="xl"
      @ok="addAction"
    >
      <div class="row">
        
        <div class="col-sm-12 form-group" v-if="checkPrivilege('CONSENT_TYPE') === true">
          <label>Consent Type :</label>
          <select v-model="consentType"  class="form-control" data-width="100%">
            <option value="PUBLIC">Public</option>
            <option value="PRIVATE" selected>Private</option>
          </select>
        </div>
        <div class="col-sm-6 form-group">
          <label>Consent Title (TH) :</label>
          <input 
            v-model="consentTitleTh"
            type="text"
            class="form-control"
            name="jQueryDetail"
            required=""
          />
        </div>

        <div class="col-sm-6 form-group">
          <label>Consent Title (EN) :</label>
          <input
            v-model="consentTitleEn"
            type="text"
            class="form-control"
            name="jQueryDetail"
            required=""
          />
        </div>

        <div class="col-sm-6 form-group">
          <label>Detail (TH) :</label>
          <textarea
            v-model="consentDetailTh"
            class="form-control"
            rows="7"
            name="jQueryDetail"
            required=""
          ></textarea>
        </div>

        <div class="col-sm-6 form-group">
          <label>Detail (EN) :</label>
          <textarea
            v-model="consentDetailEn"
            class="form-control"
            rows="7"
            name="jQueryDetail"
            required=""
          ></textarea>
        </div>

        <div class="col-sm-6 form-group">
          <label>Consent Start Date :</label>
          <b-form-datepicker v-model="consentStartDate" id="datepicker-sm1" size="lg" locale="en" class="mb-2 form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
        </div>

        <div class="col-sm-6 form-group">
          <label>Consent End Date :</label>
          <b-form-datepicker v-model="consentEndDate" id="datepicker-sm2" size="lg" locale="en" class="mb-2 form-control" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: "Modal",
  data() {
    return {
      consentType: 'PRIVATE',
      consentTitleTh: null,
      consentTitleEn: null,
      consentDetailTh: null,
      consentDetailEn: null,
      consentStartDate: null,
      consentEndDate: null,
    }
  },
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getPrivileges: 'user/getPrivileges'
	  }),
  },
  
  methods: {
	  ...mapActions({
      hasPrivilege: 'user/hasPrivilege',
      createConsent: 'consent/createConsent',
    }),
    checkPrivilege: function(privilege) {
      return this.getPrivileges.includes(privilege)
    },
    async addAction(bvModalEvt) {
      // Prevent modal from closing

     // console.log(bvModalEvt);
      // bvModalEvt.hide()
      // bvModalEvt.preventDefault();
      //alert(this.getPrivileges.includes('CONSENT_ADD'));
      let data = {
        consent_detail: this.consentDetailTh,
        consent_enddate: this.consentEndDate,
        consent_startdate: this.consentStartDate,
        consent_title_en: this.consentTitleEn,
        consent_title_th: this.consentTitleTh,
        consent_type: this.consentType
      }
      let response = await this.createConsent(data);
      if(response.statuscode == 0){
        this.showError('Success', response.statusdesc);
        this.$parent.search();
      }else{
        this.showError('Error', response.statusdesc);
      }
      
    },

    showError(title, message){
      this.$bvModal.msgBoxOk(message, {
        title: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      }).then(value => {
        
      })
      .catch(err => {
        // An error occurred
      })
    }
    //  mounted() {
    //  //  console.log(this.checkPrivilege('CONSENT_ADD')); // then logs "hello world"
    //  }  
  },
  mounted(){
    // this.$bvModal.msgBoxConfirm('กรุณากดปุ่มยืนยันเพื่อดำเนินการลบข้อมูล เมื่อยืนยันแล้วจะไม่สามารถเรียกดูข้อมูลนี้ได้อีกต่อไป', {
    //   title: 'Warning',
    //   size: 'sm',
    //   buttonSize: 'sm',
    //   okVariant: 'danger',
    //   cancelVariant: 'primary',
    //   okTitle: 'ยืนยัน',
    //   cancelTitle: 'ยกเลิก',
    //   footerClass: 'p-2',
    //   hideHeaderClose: false,
    //   centered: true
    // }).then(value => {
    // //   this.boxTwo = value
    // })
    // .catch(err => {
    //   // An error occurred
    // })
  }
};
</script>
