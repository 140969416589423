//import { MemberService, ContentService } from '../resource'


const appModule = {
    namespaced: true,
    state: {
        menu: "/",
    },
    mutations: {
        SET_MENU(state, result) {
            state.menu = result
        },
    },
    actions: {
        async setMenu({ commit, state }, data) {

            commit('SET_MENU', data)
        },
    },
    getters: {
        getMenu(state) {
            return state.menu
        },
    },
    // plugins: [vuexPersist.plugin]
}

export default appModule