<template >
  <div>
    <main>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="mb-2">
                        <h1>User Management</h1>
						<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0">
								<!--<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>
								<li class="breadcrumb-item">
									<a href="#">Library</a>
								</li>-->
								<li class="breadcrumb-item active text-gray" aria-current="page">User </li>
							</ol>
						</nav>

                    </div>

                    <div class="mb-2 d-flex justify-content-between">
						<div class="col-l">
							<a class="btn pt-0 pl-0 d-inline-block d-md-none" data-toggle="collapse" href="#displayOptions"
								role="button" aria-expanded="true" aria-controls="displayOptions">
								Display Options
								<i class="simple-icon-arrow-down align-middle"></i>
							</a>
							<div class="collapse d-md-block" id="displayOptions">
								<div class="d-flex align-items-end">
                  <div class="float-md-left mr-1 mb-1 dropdown-as-select">
										<label class="d-block">Role Name :</label>
										<button class="btn bg-white btn-outline-dark btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											All Role
										</button>
										<div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(15px, 116px, 0px); top: 0px; left: 0px; will-change: transform;">
											<a class="dropdown-item active" href="#">All Role</a>
											<a class="dropdown-item" href="#">Super Admin</a>
                      <a class="dropdown-item" href="#">Admin</a>
                      <a class="dropdown-item" href="#">User</a>
                      <a class="dropdown-item" href="#">Viewer</a>
										</div>
									</div>

									<div class="float-md-left mr-1 mb-1">
										<label class="d-block">Userame :</label>
										<input type="text" class="form-control form-control-sm bg-white rounded-2" name="UserName" placeholder="">
									</div>
									<div class="float-md-left mr-1 mb-1">
										
										<button class="btn btn-primary btn-xs text-white" type="button">
											Search
										</button>
										
									</div>
									

								</div>
							</div>
						</div>
						<div class="col-r pb-2 top-right-button-container d-flex align-items-end">
                            <a href="/user-add" class="btn btn-primary btn-md top-right-button mr-1">+ Add User</a>
								
						</div>
					</div>

                    <div class="mb-3"></div>
					<div class="card main-consent-setting">
					<div class="card-body">
						<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
							<!--<table id="tablelist" class="data-table data-table-feature">-->
							<!--<table id="tablelist" class="data-table data-table-standard">-->
                           <table class="data-table data-tables-pagination responsive nowrap">
							
									<thead>
										<tr>
											<th class="text-center pr-0" width="10%">No.</th>
											<th>UserName</th>
                      						<th>Email</th>
											<th class="text-center pr-0" width="15%">Status</th>
											<th class="text-center sort-none" width="25%">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td class="text-center">1</td>
											<td>admin</td>
                      						<td><a href="mailto:lillie.foster@example.com">lillie.foster@example.com</a></td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
											<a href="/user-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
											<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
                    					<tr>
											<td class="text-center">2</td>
											<td>admin</td>
                      						<td><a href="mailto:lillie.foster@example.com">lillie.foster@example.com</a></td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
											<a href="/user-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
											<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
										<tr>
											<td class="text-center">3</td>
											<td>admin</td>
                      						<td><a href="mailto:lillie.foster@example.com">lillie.foster@example.com</a></td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
											<a href="/user-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
											<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
										<tr>
											<td class="text-center">4</td>
											<td>admin</td>
                      						<td><a href="mailto:lillie.foster@example.com">lillie.foster@example.com</a></td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
											<a href="/user-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
											<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
										<tr>
											<td class="text-center">5</td>
											<td>admin</td>
                      						<td><a href="mailto:lillie.foster@example.com">lillie.foster@example.com</a></td>
											<td class="text-center"><span class="text-primary font-weight-semibold">Active</span></td>
											<td class="text-center">
											<a href="/user-edit" class="btn btn-primary btn-sm mr-2">Edit</a> 
											<a href="javascript:;" class="btn btn-outline-danger btn-sm">Delete</a>
											</td>
										</tr>
									</tbody>
								</table>
								
		

						</div>
					</div>
					</div>
                </div>
            </div>
        </div>


    </main>

  </div>
</template>
<script>

export default {
  data () {
    return {

    }
  },
  components: {
  
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
      
  },
  methods: {

  },
  created () {
    
  },
  mounted () {
	
	//console.log(this.myMixin)
	$(".data-table-standard").DataTable({
		bLengthChange: false,
		searching: false,
		destroy: true,
		info: false,
		sDom: '<"row view-filter"<"col-sm-12"<"float-left"l><"float-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
		pageLength: 6,
		language: {
			paginate: {
				previous: "<i class='simple-icon-arrow-left'></i>",
				next: "<i class='simple-icon-arrow-right'></i>"
			}
		},
		drawCallback: function() {
			$($(".dataTables_wrapper .pagination li:first-of-type"))
				.find("a")
				.addClass("prev");
			$($(".dataTables_wrapper .pagination li:last-of-type"))
				.find("a")
				.addClass("next");

			$(".dataTables_wrapper .pagination").addClass("pagination-sm");
		}
	});

	$(".data-tables-pagination").DataTable({
		bLengthChange: false,
		searching: false,
		destroy: true,
		info: false,
		sDom: '<"row view-filter"<"col-sm-12"<"float-left"l><"float-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
		pageLength: 8,
		language: {
			paginate: {
				previous: "<i class='simple-icon-arrow-left'></i>",
				next: "<i class='simple-icon-arrow-right'></i>"
			}
		},
		drawCallback: function() {
			$($(".dataTables_wrapper .pagination li:first-of-type"))
				.find("a")
				.addClass("prev");
			$($(".dataTables_wrapper .pagination li:last-of-type"))
				.find("a")
				.addClass("next");

			$(".dataTables_wrapper .pagination").addClass("pagination-sm");
		}
	});

	var dataTablePs;
	$(".data-table-scrollable").DataTable({
		searching: false,
		bLengthChange: false,
		destroy: true,
		info: false,
		paging: false,
		sDom: '<"row view-filter"<"col-sm-12"<"float-left"l><"float-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
		responsive: !0,
		deferRender: !0,
		scrollY: "calc(100vh - 400px)",
		scrollCollapse: !0,
		"fnInitComplete": function() {
			dataTablePs = new PerfectScrollbar('.dataTables_scrollBody', { suppressScrollX: true });
			dataTablePs.isRtl = false;
		},
		"fnDrawCallback": function(oSettings) {
			dataTablePs = new PerfectScrollbar('.dataTables_scrollBody', { suppressScrollX: true });
			dataTablePs.isRtl = false;
		}
	});

	$(".data-table-feature").DataTable({
		sDom: '<"row view-filter"<"col-sm-12"<"float-right"l><"float-left"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
		"columns": [
			{ "data": "name" },
			{ "data": "position" },
			{ "data": "office" },
			{ "data": "age" },
			{ "data": "start_date" },
			{ "data": "salary" }
		],
		drawCallback: function() {
			$($(".dataTables_wrapper .pagination li:first-of-type"))
				.find("a")
				.addClass("prev");
			$($(".dataTables_wrapper .pagination li:last-of-type"))
				.find("a")
				.addClass("next");

			$(".dataTables_wrapper .pagination").addClass("pagination-sm");
		},
		language: {
			paginate: {
				previous: "<i class='simple-icon-arrow-left'></i>",
				next: "<i class='simple-icon-arrow-right'></i>"
			},
			search: "_INPUT_",
			searchPlaceholder: "Search...",
			lengthMenu: "Items Per Page _MENU_"
		},
	});

	// Datatable with rows
	var $dataTableRows = $("#datatableRows").DataTable({
		bLengthChange: false,
		destroy: true,
		info: false,
		sDom: '<"row view-filter"<"col-sm-12"<"float-left"l><"float-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
		pageLength: 10,
		columns: [
			{ data: "Name" },
			{ data: "Sales" },
			{ data: "Stock" },
			{ data: "Category" },
			{ data: "Check" }
		],
		language: {
			paginate: {
				previous: "<i class='simple-icon-arrow-left'></i>",
				next: "<i class='simple-icon-arrow-right'></i>"
			}
		},
		drawCallback: function() {
			unCheckAllRows();
			$("#checkAllDataTables").prop("checked", false);
			$("#checkAllDataTables").prop("indeterminate", false).trigger("change");

			$($(".dataTables_wrapper .pagination li:first-of-type"))
				.find("a")
				.addClass("prev");
			$($(".dataTables_wrapper .pagination li:last-of-type"))
				.find("a")
				.addClass("next");
			$(".dataTables_wrapper .pagination").addClass("pagination-sm");
			var api = $(this).dataTable().api();
			$("#pageCountDatatable span").html("Displaying " + parseInt(api.page.info().start + 1) + "-" + api.page.info().end + " of " + api.page.info().recordsTotal + " items");
		}
	});

	$('#datatableRows tbody').on('click', 'tr', function() {
		$(this).toggleClass('selected');
		var $checkBox = $(this).find(".custom-checkbox input");
		$checkBox.prop("checked", !$checkBox.prop("checked")).trigger("change");
		controlCheckAll();
	});

	function controlCheckAll() {
		var anyChecked = false;
		var allChecked = true;
		$('#datatableRows tbody tr .custom-checkbox input').each(function() {
			if ($(this).prop("checked")) {
				anyChecked = true;
			} else {
				allChecked = false;
			}
		});
		if (anyChecked) {
			$("#checkAllDataTables").prop("indeterminate", anyChecked);
		} else {
			$("#checkAllDataTables").prop("indeterminate", anyChecked);
			$("#checkAllDataTables").prop("checked", anyChecked);
		}
		if (allChecked) {
			$("#checkAllDataTables").prop("indeterminate", false);
			$("#checkAllDataTables").prop("checked", allChecked);
		}
	}

	function unCheckAllRows() {
		$('#datatableRows tbody tr').removeClass('selected');
		$('#datatableRows tbody tr .custom-checkbox input').prop("checked", false).trigger("change");
	}

	function checkAllRows() {
		$('#datatableRows tbody tr').addClass('selected');
		$('#datatableRows tbody tr .custom-checkbox input').prop("checked", true).trigger("change");
	}

	$("#checkAllDataTables").on("click", function(event) {
		var isCheckedAll = $("#checkAllDataTables").prop("checked");
		if (isCheckedAll) {
			checkAllRows();
		} else {
			unCheckAllRows();
		}
	});

	function getSelectedRows() {
		//Getting Selected Ones
		console.log($dataTableRows.rows('.selected').data());
	}

	$("#searchDatatable").on("keyup", function(event) {
		$dataTableRows.search($(this).val()).draw();
	});

	$("#pageCountDatatable .dropdown-menu a").on("click", function(event) {
		var selText = $(this).text();
		$dataTableRows.page.len(parseInt(selText)).draw();
	});

	var $addToDatatableButton = $("#addToDatatable").stateButton();

	// Validation when modal shown
	$('#rightModal').on('shown.bs.modal', function(e) {
		$("#addToDatatableForm").validate({
			rules: {
				Sales: {
					required: true,
					number: true,
					min: 3000
				},
				Stock: {
					required: true,
					number: true,
				},
				Category: {
					required: true
				},
				Name: {
					required: true
				}
			}
		})
	})

	//Adding to datatable from right modal
	$("#addToDatatable").on("click", function(event) {
		if ($("#addToDatatableForm").valid()) {
			$addToDatatableButton.showSpinner();
			var inputs = $("#addToDatatableForm").find(':input');
			var data = {};
			inputs.each(function() {
				data[$(this).attr("name")] = $(this).val();
			});
			data["Check"] = '<label class="custom-control custom-checkbox mb-1 align-self-center data-table-rows-check"><input type="checkbox" class="custom-control-input"><span class="custom-control-label">&nbsp;</span></label>';
			$dataTableRows.row.add(data).draw();
			setTimeout(function() {
				$addToDatatableButton.showSuccess(true, "New row added!");
				setTimeout(function() {
					$("#rightModal").modal("toggle");
					$addToDatatableButton.reset();
					inputs.each(function() {
						$(this).val("");
					});
					if ($("#addToDatatableForm").find('select').data('select2')) {
						$("#addToDatatableForm").find('select').val('').trigger('change');
					}
					$("#addToDatatableForm").validate().resetForm();
				}, 1000);
			}, 1000);
		}
	});

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
