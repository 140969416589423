<template >
  <div>
    <main>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="mb-2">
              <h1>Role Management</h1>
              <nav
                class="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                aria-label="breadcrumb"
              >
                <ol class="breadcrumb pt-0">
                  <!--<li class="breadcrumb-item">
									<a href="#">Home</a>
                  </li>-->
                  <li class="breadcrumb-item">
                    <a href="/role-list">Role</a>
                  </li>
                  <li class="breadcrumb-item active text-gray" aria-current="page">Add Role</li>
                </ol>
              </nav>
            </div>

            <div class="mb-3"></div>

            <div class="d-flex justify-content-center mb-4">
              <div class="col-sm-10 col-md-8 col-lg-6">
                <div class="card h-100 d-flex justify-content-center flex-row">
                  <div class="card-body col-md-9 col-lg-8">

                    <div class="wr mb-4">
                      <label>Role :</label>
                      <input type="text" class="form-control" placeholder="Role from AD" />
                    </div>

                    <div class="wr mb-4">
                      <label>Role Name TH :</label>
                      <input type="text" class="form-control" placeholder="Role Name TH" />
                    </div>

                    <div class="wr mb-4">
                      <label>Role Name EN :</label>
                      <input type="text" class="form-control" placeholder="Role Name EN" />
                    </div>

                    <div class="wr mb-4">
                      <label>Role Detail :</label>
                      <textarea class="form-control" rows="10"></textarea>
                    </div>

                    <div class="wr mb-4">
                      <h3 class="h6"><u>Privilege</u></h3>
                      <label>- Report:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserAdd" />
                        <label class="custom-control-label" for="UserAdd">Dashboard</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserEdit" />
                        <label class="custom-control-label" for="UserEdit">Report 1</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserDel" />
                        <label class="custom-control-label" for="UserDel">Report 2</label>
                      </div>

                      <label>- User Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserAdd" />
                        <label class="custom-control-label" for="UserAdd">Add User</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserEdit" />
                        <label class="custom-control-label" for="UserEdit">Edit User</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="UserDel" />
                        <label class="custom-control-label" for="UserDel">Delete User</label>
                      </div>

                      <label>- Role Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Role</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Role</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Role</label>
                      </div>

                      <label>- Consent Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Consent</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Consent</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Consent</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Publish Consent</label>
                      </div>

                      <label>- Consent Group Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Consent Group</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Consent Group</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Consent Group</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Publish Consent Group</label>
                      </div>

                      <label>- Privacy Notice Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Privacy Notice</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Privacy Notice</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Privacy Notice</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Publish Privacy Notice</label>
                      </div>

                      <label>- Customer Management:</label>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleAdd" />
                        <label class="custom-control-label" for="roleAdd">Add Customer</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleEdit" />
                        <label class="custom-control-label" for="roleEdit">Edit Customer</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Delete Customer</label>
                      </div>
                      <div class="custom-control custom-checkbox mb-2 ml-4">
                        <input type="checkbox" class="custom-control-input" id="roleDel" />
                        <label class="custom-control-label" for="roleDel">Publish Customer</label>
                      </div>

                    </div>

                    <!-- <div class="wr mb-4">
                      <label>Status :</label>
                      <select class="form-control select2-single" data-width="100%">
                        <option value="0" selected>Active</option>
                        <option value="1">Disable</option>
                      </select>
                    </div> -->

                    <div class="d-flex justify-content-center mb-4">
                      <a href="javascript:history.back();">
                      <button type="button" class="btn btn-gray btn-sm mb-2 mr-1 min-w">Reset</button>
                      </a>

                      <button type="button" class="btn btn-primary btn-sm mb-2 ml-1 min-w">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  async beforeCreate() {},
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
  destroyed() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
