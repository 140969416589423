<template >
  <div>

      <main>
        <div class="container-fluid">
            <div class="row">
               <input type="button" value="test" @click="testStore()" />
            </div>
            <div class="row">
               your name : {{token}}
            </div>
        </div>

    </main>

  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  components: {
  
  },
  beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
    ...mapGetters({
      token: 'member/getToken'
    })
  },
  methods: {
    ...mapActions({
        loginMember: 'member/loginMember'
    }),
    testStore(){
       // alert('ok');
       this.loginMember('Sarawut')
    }
  },
  created () {
    
  },
  mounted () {
    

  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
