<template >
  <div>

      <nav class="navbar fixed-top" v-if="isLoggedIn">
        <div class="d-flex align-items-center navbar-left">
            <a href="#" class="menu-button d-none d-md-block">
                <img src="di/ic-menu.png">
            </a>

            <a href="#" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
                  <rect x="0.5" y="0.5" width="25" height="1" />
                  <rect x="0.5" y="7.5" width="25" height="1" />
                  <rect x="0.5" y="15.5" width="25" height="1" />
                </svg>
            </a>

            <!-- <div class="mr-2 dropdown-as-select" id="search-type">
                <button class="btn btn-light btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item active" href="#">ID Card</a>
                    <a class="dropdown-item" href="#">Name</a>
                    <a class="dropdown-item" href="#">Mobile Number</a>
                </div>
            </div>

            <div class="search" data-search-path="Pages.Search.html?q=">
                <input placeholder="Example : 12345678">
                <span class="search-icon">
          <i class="simple-icon-magnifier"></i>
        </span>
            </div> -->

            <!-- <div class="link-adv-search">
                <a href="javascript:;" class="text-primary ml-2 nowrap" data-toggle="modal" data-backdrop="static" data-target="#advance-srh">Advance Search</a>

            </div> -->



        </div>


        <a class="navbar-logo" href="/">
            <span class="brand-logo d-none d-xs-block"><img src="/di/logo-ntl.png" height="55"></span>
            <span class="brand-logo-mobile d-block d-xs-none"><img src="/di/logo-ntl.png" height="35"></span>
        </a>

        <div class="navbar-right d-flex justify-content-end align-items-center">
            <div class="position-relative d-inline-block mr-3">
                    <button class="header-icon btn btn-empty" type="button" id="notificationButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="simple-icon-bell"></i>
                        <span class="count">3</span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right mt-3 position-absolute" id="notificationDropdown">
                        <div class="scroll">
                            <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                                <div class="pl-3">
                                    <a href="#">
                                        <p class="font-weight-medium mb-1">Your consent request for approve was Reject by admin</p>
                                        <p class="text-muted mb-0 text-small">09-Sep-2020 12:45:00</p>
                                    </a>
                                </div>
                            </div>
                            <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                                <div class="pl-3">
                                   <a href="#">
                                        <p class="font-weight-medium mb-1">Your consent request for approve was Approve by admin</p>
                                        <p class="text-muted mb-0 text-small">09-Sep-2020 12:45:00</p>
                                    </a>
                                </div>
                            </div>
                            
                            <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                                <div class="pl-3">
                                   <a href="#">
                                        <p class="font-weight-medium mb-1">Your consent request for approve was Approve by admin</p>
                                        <p class="text-muted mb-0 text-small">09-Sep-2020 12:45:00</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
             </div>

            <div class="user d-inline-block mr-0">
                <button class="btn btn-empty p-0 d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="name text-right">Sarawut
            <small class="d-block">ADMIN</small>
          </span>
          <!-- <span>
            <img alt="Profile Picture" src="img/profile-pic-l.jpg" />
          </span> -->
        </button>

                <div class="dropdown-menu dropdown-menu-right mt-3">
                    <a class="dropdown-item" v-on:click="logoutAction()" >Sign out</a>
                </div>
            </div>

            <div class="ml-2 mr-2 lang d-inline-block dropdown-as-select" id="lang">
                <button class="btn btn-light btn-xs pl-2 pr-2 dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          EN
        </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item active" href="#">EN</a>
                    <a class="dropdown-item" href="#">TH</a>
                </div>
            </div>
        </div>
    </nav>

  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'Header',
  data () {
    return {

    }
  },
  components: {
      
  },
  async beforeCreate () {
      
  },
  watch: {
      
  }, 
  computed: {
    ...mapGetters({
      menu: 'app/getMenu',
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
        setMenu: 'app/setMenu',
        logout: 'user/logout',
    }),
    testStore(){
       // alert('ok');
       this.setMenu('Sarawut')
    },
    logoutAction(){
       // alert('ok');
       this.logout()
       this.$router.push('/login')
    }
  },
  created () {
    
  },
  mounted () {
    console.log(this.isLoggedIn);
  },
  destroyed () {
   
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
