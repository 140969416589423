import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
//import i18n from './plugins/i18n'
//import swal from 'sweetalert';
import router from './router'
import store from './store'
//Vue.prototype.$swal = swal;
//import axios from 'axios'
import VueSwal from 'vue-swal'
import VueToast from 'vue-toast-notification';
// Import one of available themes
import 'vue-toast-notification/dist/theme-default.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
//import 'vue-toast-notification/dist/theme-sugar.css';
import { EagleModal } from 'vue-eagle-modal'
import vmodal from 'vue-js-modal'


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
    //Vue.use(vmodal)
    //Vue.use(vmodal, { dialog: true })
Vue.use(vmodal, {
    dynamicDefaults: {
        draggable: true,
        resizable: true,
        height: 'auto'
    }
})

Vue.use(EagleModal);
Vue.use(VueToast, {
    position: 'top'
})
Vue.use(VueSwal)

var myMixin = {
    created: function() {
        this.hello()
    },
    methods: {
        hello: function() {
            console.log('hello from mixin!')
        }
    }
}
Vue.use(myMixin)
    // define a component that uses this mixin
var Component = Vue.extend({
        mixins: [myMixin]
    })
    //var component = new Component()

new Vue({
    router,
    store,
    //  swal,
    render: h => h(App),
}).$mount('#app')