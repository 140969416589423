import Vue from 'vue'

import store from '../store'
import Router from 'vue-router'
import Dashboard from '@/views/Dashboard'
import Test from '@/views/Test'

import Login from '@/views/Login'

import RoleList from '@/views/RoleList'
import RoleAdd from '@/views/RoleAdd'
import RoleEdit from '@/views/RoleEdit'
import RoleView from '@/views/RoleView'

import UserList from '@/views/UserList'
import UserAdd from '@/views/UserAdd'
import UserEdit from '@/views/UserEdit'
import UserView from '@/views/UserView'

import CustomerList from '@/views/CustomerList'
import CustomerAdd from '@/views/CustomerAdd'
import CustomerEdit from '@/views/CustomerEdit'
import CustomerView from '@/views/CustomerView'

import PrivacyList from '@/views/PrivacyList'
import PrivacyAdd from '@/views/PrivacyAdd'
import PrivacyEdit from '@/views/PrivacyEdit'
import PrivacyView from '@/views/PrivacyView'

import ConsentList from '@/views/ConsentList'
import ConsentAdd from '@/views/ConsentAdd'
import ConsentEdit from '@/views/ConsentEdit'
import ConsentView from '@/views/ConsentView'

import SuperConsentList from '@/views/SuperConsentList'
import SuperConsentAdd from '@/views/SuperConsentAdd'
import SuperConsentEdit from '@/views/SuperConsentEdit'
import SuperConsentView from '@/views/SuperConsentView'

import ActivityLog from '@/views/ActivityLog'

import ConsentApprove from '@/views/ConsentApprove'
import SuperConsentApprove from '@/views/SuperConsentApprove'
import PrivacyApprove from '@/views/PrivacyApprove'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/test',
            name: 'Test',
            component: Test
        },
        {
            path: '/role-list',
            name: 'RoleList',
            component: RoleList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/role-add',
            name: 'RoleAdd',
            component: RoleAdd,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/role-edit',
            name: 'RoleEdit',
            component: RoleEdit,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/role-view',
            name: 'RoleView',
            component: RoleView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/user-list',
            name: 'UserList',
            component: UserList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/user-add',
            name: 'UserAdd',
            component: UserAdd,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/user-edit',
            name: 'UserEdit',
            component: UserEdit,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/user-view',
            name: 'UserView',
            component: UserView,
            meta: {
                requiresAuth: true
            }
        },


        {
            path: '/consent-list',
            name: 'ConsentList',
            component: ConsentList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/consent-add',
            name: 'ConsentAdd',
            component: ConsentAdd,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/consent-edit',
            name: 'ConsentEdit',
            component: ConsentEdit,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/consent-detail',
            name: 'ConsentView',
            component: ConsentView,
            meta: {
                requiresAuth: true
            }
        },

        {
            path: '/superconsent-list',
            name: 'SuperConsentList',
            component: SuperConsentList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/superconsent-add',
            name: 'SuperConsentAdd',
            component: SuperConsentAdd,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/superconsent-edit',
            name: 'SuperConsentEdit',
            component: SuperConsentEdit,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/superconsent-detail',
            name: 'SuperConsentView',
            component: SuperConsentView,
            meta: {
                requiresAuth: true
            }
        },

        {
            path: '/privacy-list',
            name: 'PrivacyList',
            component: PrivacyList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/privacy-add',
            name: 'PrivacyAdd',
            component: PrivacyAdd,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/privacy-edit',
            name: 'PrivacyEdit',
            component: PrivacyEdit,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/privacy-detail',
            name: 'PrivacyView',
            component: PrivacyView,
            meta: {
                requiresAuth: true
            }
        },


        {
            path: '/customer-list',
            name: 'CustomerList',
            component: CustomerList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/customer-add',
            name: 'CustomerAdd',
            component: CustomerAdd,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/customer-edit',
            name: 'CustomerEdit',
            component: CustomerEdit,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/customer-view',
            name: 'CustomerView',
            component: CustomerView,
            meta: {
                requiresAuth: true
            }
        },

        {
            path: '/activitylog',
            name: 'ActivityLog',
            component: ActivityLog,
            meta: {
                requiresAuth: true
            }
        },



        {
            path: '/superconsent-approve',
            name: 'SuperConsentApprove',
            component: SuperConsentApprove,
            meta: {
                requiresAuth: true
            }
        },

        {
            path: '/consent-approve',
            name: 'ConsentApprove',
            component: ConsentApprove,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/privacy-approve',
            name: 'PrivacyApprove',
            component: PrivacyApprove,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },




    ]
})

router.beforeEach((to, from, next) => {
    //
    let menu = store.getters['app/getMenu'];
    console.log(to.path, from.path);
    if (to.path == '/') {
        //     this.menu = "dashboard"
        //store.setters['app/setMenu'];
        store.commit('app/SET_MENU', 'dashboard')
    } else if (to.path == '/customer-list' || to.path == '/customer-view' || to.path == '/customer-add' || to.path == '/customer-edit') {
        //  this.menu = "customer"
        store.commit('app/SET_MENU', 'customer')
    } else if (to.path == '/consent-list' || to.path == '/consent-view' || to.path == '/consent-add' || to.path == '/consent-edit' ||
        to.path == '/superconsent-list' || to.path == '/superconsent-view' || to.path == '/superconsent-add' || to.path == '/superconsent-edit' ||
        to.path == '/privacy-list' || to.path == '/privacy-view' || to.path == '/privacy-add' || to.path == '/privacy-edit' || to.path == '/privacy-detail' ||
        to.path == '/consent-detail' || to.path == '/superconsent-detail' ||
        to.path == '/superconsent-approve' ||
        to.path == '/consent-approve' ||
        to.path == '/privacy-approve') {
        //    this.menu = "consent"
        store.commit('app/SET_MENU', 'consent')
    } else if (to.path == '/report' || to.path == '/activitylog') {
        //   this.menu = "report"
        store.commit('app/SET_MENU', 'report')
    } else if (to.path == '/setting' || to.path == '/role-list' || to.path == '/role-add' || to.path == '/role-edit' || to.path == '/role-view' ||
        to.path == '/user-list' || to.path == '/user-add' || to.path == '/user-edit' || to.path == '/user-view') {
        //     this.menu = "setting"
        store.commit('app/SET_MENU', 'setting')
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        let isLoggedIn = store.getters['user/isLoggedIn'];
        // alert(isLoggedIn);
        if (isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        // alert('else');
        next()
    }
})

export default router